import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { useAuth } from '@/hooks/useAuth';
import { Calendar } from '@/pages/DashboardPage/SectionStats/Calendar';
import { InterviewStatsSection } from '@/pages/DashboardPage/SectionStats/Interviews/InterviewStatsSection';

export const DashboardPage = () => {
  const { user } = useAuth();

  return (
    <BasicLayout title={'Dashboard'}>
      <div className={'flex flex-col gap-4'}>
        {!user?.roles?.length ? (
          <p className={'p-4 text-xl italic'}>You do not have any roles.</p>
        ) : null}
        <InterviewStatsSection />
        <Calendar />
      </div>
    </BasicLayout>
  );
};
