import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetDinnersQuery,
  useGetDinnersQuery,
} from '@/__generated__/graphql';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import BaseTable from '@/components/baseTable/BaseTable';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { CityEnum } from '@/shared/enums';
import { removeInvalidFilters } from '@/shared/utils/utils';

export const DinnersPage = () => {
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FilterInput[]>([]);
  const {
    data: dinnerRes,
    loading,
    refetch: dinnersRefetch,
  } = useGetDinnersQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const dinners = dinnerRes?.getDinners.dinners || [];
  const totalRecords = dinnerRes?.getDinners.count;

  const columns: ColumnDef<GetDinnersQuery['getDinners']['dinners'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'codename',
      header: 'Codename',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'date',
      header: 'Date',
      accessorFn: (record) =>
        record.date ? dayjs(new Date(parseInt(record.date))).format('MMM D, YYYY h:mm A z') : '',
    },
    {
      accessorKey: 'city',
      enableSorting: false,
      header: 'City',
    },
    {
      accessorFn: (row) => row.location?.name,
      enableSorting: false,
      header: 'Location',
    },
    {
      accessorFn: (record) =>
        record?.pricePerSeat ? `$${(record?.pricePerSeat / 100).toFixed(2)}` : '',
      enableSorting: false,
      header: 'Price/Seat',
    },
    {
      accessorFn: (record) =>
        record?.costPerSeat ? `$${(record?.costPerSeat / 100).toFixed(2)}` : '',
      enableSorting: false,
      header: 'Cost/Seat',
    },
    {
      accessorFn: () => 8,
      enableSorting: false,
      header: 'Max Seats',
    },
    {
      accessorFn: (record) => record.description,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          {<InformationCircleIcon title={cell.getValue() as any} className={'w-4'} />}
        </div>
      ),
      enableSorting: false,
      header: 'Description',
    },
  ];

  const getFilters = (): IFilter[] => {
    return [
      {
        field: 'city',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(CityEnum),
      },
      {
        field: 'date',
        type: 'date',
        operations: [FilterConditionOperatorEnum.GreaterThan, FilterConditionOperatorEnum.LessThan],
      },
      {
        field: 'status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Contains],
      },
    ];
  };

  useEffect(() => {
    dinnersRefetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    });
  }, [filters, sorting, pagination]);

  return (
    <BasicLayout title={'Dinners'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
      </div>

      <BaseTable
        data={dinners}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
      />
    </BasicLayout>
  );
};
