import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Button, { ButtonProps } from './Button';

export interface DropdownAction {
  label: string;
  onClick?: () => Promise<void> | void;
  type?: 'button' | 'submit';
}

interface DropdownButtonProps extends ButtonProps {
  dropdownActions: DropdownAction[];
  vertical?: 'top' | 'bottom';
}

export const ButtonWithDropdown: React.FC<DropdownButtonProps> = ({
  children,
  dropdownActions,
  color = 'primary',
  size = 'md',
  className = '',
  disabled = false,
  vertical,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`dropdown dropdown-end ${vertical === 'top' ? 'dropdown-top' : ''} dropdown-hover`}
      onMouseEnter={() => setIsOpen(true)}
    >
      {/* Default button */}
      <Button
        color={color}
        size={size}
        className={twMerge('flex items-center justify-between', className)}
        disabled={disabled}
        {...props}
      >
        {children}
      </Button>

      {/* Dropdown menu */}
      {isOpen && (
        <ul
          tabIndex={0}
          className="menu dropdown-content z-20 w-52 rounded-box border bg-base-100 p-2 shadow-lg"
        >
          {dropdownActions.map((action, index) => (
            <li key={index} className="w-full">
              <Button
                color={'ghost'}
                variant={'ghost'}
                size={size}
                type={action.type}
                className="w-full text-left"
                onClick={async () => {
                  if (action.onClick) {
                    await action.onClick();
                  }
                  setTimeout(() => setIsOpen(false), 0);
                }}
              >
                <span className={'w-full text-left'}>{action.label}</span>
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
