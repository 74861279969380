import { GetStatsQuery } from '@/__generated__/graphql';
import { SimpleTable } from '@/components/tables/SimpleTable';

type Props = {
  stats: GetStatsQuery['getInterviewStats'];
  isAdmin?: boolean;
};

export const InterviewStatsTable = ({ isAdmin, stats }: Props) => {
  const getAverageClose = () => {
    if (!stats || !stats.length) {
      return '-';
    }

    const [wins, total] = stats.reduce(
      (acc, stat) => {
        if (!stat.totalCompletedInterviews) {
          return [acc[0], acc[1]];
        }
        return [acc[0] + stat.interviewWins, acc[1] + stat.interviewWins + stat.interviewLoss];
      },
      [0, 0] as [number, number],
    );

    return ((wins / (total || 1)) * 100).toFixed(2) + '%';
  };

  return (
    <div>
      <div className={'flex min-h-[300px] gap-2 p-2'}>
        <SimpleTable
          data={(stats ? [...stats] : []).sort((a, b) => b.closeRate - a.closeRate)}
          columns={[
            { accessorKey: 'interviewerName', headerName: 'Interviewer' },
            ...(isAdmin
              ? [
                  { accessorKey: 'totalInterviews', headerName: 'Total' },
                  { accessorKey: 'totalCompletedInterviews', headerName: 'Completed' },
                  { accessorKey: 'interviewWins', headerName: 'Wins' },
                  { accessorKey: 'interviewLoss', headerName: 'Losses' },
                ]
              : [{ accessorKey: 'interviewWins', headerName: 'Wins' }]),
            {
              accessorKey: 'winsPct',
              headerName: 'Close Rate',
              accessorFunc: (record) => {
                return (record.closeRate * 100).toFixed(0) + '%';
              },
            },
          ]}
        />
      </div>
      <div
        className={
          'flex w-full justify-end gap-4 border-t py-1 pr-10 text-right font-mono text-xs font-semibold'
        }
      >
        <p>Team Average Close Rate: {getAverageClose()}</p>|
        <p>Team Total Wins: {stats?.reduce((acc, s) => acc + s.interviewWins, 0)}</p>
      </div>
    </div>
  );
};
