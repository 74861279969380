import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

import { Person } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { getStripeUrl } from '@/shared/utils/utils';

type Props = {
  person: Person;
};

export const PersonSubscription = ({ person }: Props) => {
  const subscription = person?.subscription;
  const details = [
    {
      title: 'Status',
      value: subscription?.status,
    },
    {
      title: 'Amount',
      value: subscription?.amount
        ? `$${(subscription?.amount / 100).toFixed(2)} / ${subscription?.interval}`
        : null,
    },
    {
      title: 'Payment Cycle End',
      value: subscription?.currentPeriodEnd
        ? dayjs(subscription.currentPeriodEnd).format('MMMM DD, YYYY')
        : '',
    },
    {
      title: 'Payment Cycle Start',
      value: subscription?.currentPeriodStart
        ? dayjs(subscription.currentPeriodStart).format('MMMM DD, YYYY')
        : '',
    },
    {
      title: 'Plan Start',
      value: subscription?.startDate ? dayjs(subscription.startDate).format('MMMM DD, YYYY') : '',
    },
    {
      title: 'Canceled On',
      value: subscription?.canceledAt
        ? dayjs(
            subscription?.cancelAtPeriodEnd ? subscription.cancelAt : subscription.canceledAt,
          ).format('MMMM DD, YYYY')
        : '',
    },
    {
      title: 'Initiated Cancel On',
      value: subscription?.canceledAt ? dayjs(subscription.canceledAt).format('MMMM DD, YYYY') : '',
    },
    {
      title: 'Subscription ID',
      value: subscription?.subscriptionId ? (
        <>
          <span>{subscription.subscriptionId}</span>
          <Button
            onClick={() =>
              window.open(`${getStripeUrl()}/subscriptions/${subscription?.subscriptionId}`)
            }
            color={'ghost'}
            size={'xs'}
            variant={'link'}
          >
            <ArrowUpRightIcon color={'black'} className={'h-4 w-4'} />
          </Button>
        </>
      ) : null,
    },
  ];

  return <DetailTableSection title={'Subscription'} details={details} />;
};
