import { InformationCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetExperiencesQuery,
  useGetExperiencesQuery,
} from '@/__generated__/graphql';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import BaseTable from '@/components/baseTable/BaseTable';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { BadgeCell } from '@/components/tables/tanstackComponents/BadgeCell';
import { UpsertExperienceModal } from '@/pages/Experiences/ExperiencesPage/UpsertExperienceModal/UpsertExperienceModal';
import { ExperienceDetailsSideDrawer } from '@/pages/Experiences/ExperiencesPage/experienceDetails/ExperienceDetailsSideDrawer';
import { CityEnum, ExperienceCategoryEnum, ExperienceStatusEnum } from '@/shared/enums';
import { removeInvalidFilters } from '@/shared/utils/utils';

export const ExperiencesPage = () => {
  const FILTERS_STORAGE_KEY = 'experiences_filters';
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FilterInput[]>(() => {
    // Load initial filters from local storage if available
    const storedFilters = localStorage.getItem(FILTERS_STORAGE_KEY);
    return storedFilters ? JSON.parse(storedFilters) : [];
  });
  const [searchParams] = useSearchParams();
  const debouncedSearch = useRef(
    debounce(async (criteria: string) => {
      setSearchTerm(criteria);
    }, 300),
  ).current;
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const {
    data: experiencesRes,
    loading,
    refetch: experiencesRefetch,
  } = useGetExperiencesQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const experiences = experiencesRes?.getExperiences.experiences || [];
  const totalRecords = experiencesRes?.getExperiences.count;
  const [selectedExperienceId, setSelectedExperienceId] = useState(searchParams.get('e') || '');
  const [editExperienceId, setEditExperienceId] = useState('');
  const [quickFilters, setQuickFilters] = useState<Record<string, string>>({
    time: 'all',
    status: 'all',
  });

  const columns: ColumnDef<GetExperiencesQuery['getExperiences']['experiences'][0]>[] = [
    {
      id: 'action',
      accessorFn: (record) => record.id,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          <Button
            color={'ghost'}
            size={'xs'}
            onClick={(e) => {
              e.stopPropagation();
              setEditExperienceId(cell.getValue() as string);
              setShowEditExperienceModal(true);
            }}
          >
            <PencilIcon className={'w-4'} />
          </Button>
        </div>
      ),
      enableSorting: false,
      header: 'Edit',
    },
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: BadgeCell,
    },
    {
      accessorKey: 'title',
      header: 'Title',
      cell: ({ cell }) => (
        <div
          className={'min-w-full max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap'}
          title={cell.getValue() as any}
        >
          {cell.getValue() as any}
        </div>
      ),
    },
    {
      accessorKey: 'experienceCategory',
      header: 'Category',
      cell: BadgeCell,
    },
    {
      accessorKey: 'date',
      header: 'Date',
      accessorFn: (record) =>
        record.date ? dayjs(record.date).format('MMM D, YYYY h:mm A z') : '',
    },
    {
      accessorFn: (record) => record?.location?.name,
      header: 'Location',
      enableSorting: false,
    },
    {
      accessorKey: 'city',
      header: 'City',
      enableSorting: false,
      cell: BadgeCell,
    },
    {
      accessorFn: (record) =>
        record?.pricePerSeat ? `$${(record?.pricePerSeat / 100).toFixed(2)}` : '',
      enableSorting: false,
      header: 'Price/Seat',
    },
    {
      accessorFn: (record) => record?.maxParticipants ?? '',
      enableSorting: false,
      header: 'Total Tickets',
    },
    {
      accessorKey: 'ticketsLeft',
      enableSorting: false,
      header: 'Available Tickets',
    },
    {
      accessorKey: 'revenue',
      enableSorting: false,
      header: 'Revenue',
      accessorFn: (record) => {
        const revenue = record.pricePerSeat
          ? Math.max((record.maxParticipants || 0) - (record.ticketsLeft || 0), 0) *
            (record.pricePerSeat / 100)
          : 0;

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD', // Formats with a $ symbol and includes commas
        });

        return revenue ? formatter.format(revenue) : '';
      },
    },
    {
      accessorKey: 'maxGuestsPerMember',
      enableSorting: false,
      header: 'Guest/Member',
    },
    {
      accessorFn: (record) => record.description,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          {<InformationCircleIcon title={cell.getValue() as any} className={'w-4'} />}
        </div>
      ),
      enableSorting: false,
      header: 'Description',
    },
  ];

  const getFilters = (): IFilter[] => {
    return [
      {
        field: 'city',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(CityEnum),
      },
      {
        field: 'date',
        type: 'date',
        operations: [FilterConditionOperatorEnum.GreaterThan, FilterConditionOperatorEnum.LessThan],
      },
      {
        field: 'status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(ExperienceStatusEnum),
      },
      {
        field: 'experience_category',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(ExperienceCategoryEnum),
      },
    ];
  };

  const handleRefetchExperiences = async () => {
    const tempFilters = [
      ...filters,
      {
        field: 'search',
        value: searchTerm,
        operator: FilterConditionOperatorEnum.Contains,
      },
    ];

    await experiencesRefetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(tempFilters),
    });
  };

  const handleEditModalClose = () => {
    setEditExperienceId('');
    setShowEditExperienceModal(false);
    handleRefetchExperiences();
  };

  const handleQuickFilters = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuickFilters({
      ...quickFilters,
      [name]: value,
    });

    if (name === 'time') {
      const dateFilter: FilterInput = filters.find((filter) => filter.field === 'date') || {
        field: 'date',
        operator: FilterConditionOperatorEnum.GreaterThan,
        value: '',
      };
      const newFilters = filters.filter((filter) => filter.field !== 'date');
      if (value === 'all') {
        setFilters(newFilters);
      } else if (value === 'upcoming') {
        dateFilter.operator = FilterConditionOperatorEnum.GreaterThanEquals;
        dateFilter.value = dayjs().startOf('day').toString();
        newFilters.push(dateFilter);
        setFilters(newFilters);
      } else if (value === 'past') {
        dateFilter.operator = FilterConditionOperatorEnum.LessThanEquals;
        dateFilter.value = dayjs().startOf('day').toString();
        newFilters.push(dateFilter);
        setFilters(newFilters);
      }
    } else if (name === 'status') {
      const statusFilter: FilterInput = filters.find((filter) => filter.field === 'status') || {
        field: 'status',
        operator: FilterConditionOperatorEnum.Equals,
        value: '',
      };
      const newFilters = filters.filter((filter) => filter.field !== 'status');
      if (value === 'all') {
        setFilters(newFilters);
      } else {
        statusFilter.value = value;
        newFilters.push(statusFilter);
        setFilters(newFilters);
      }
    }
  };

  // Function to initialize quickFilters based on the provided filters array
  const initializeQuickFilters = () => {
    const timeFilter = filters.find((filter) => filter.field === 'date');
    const statusFilter = filters.find((filter) => filter.field === 'status');

    const newQuickFilters = { ...quickFilters };

    // Initialize 'time' quick filter
    if (timeFilter) {
      if (
        timeFilter.operator === FilterConditionOperatorEnum.GreaterThanEquals &&
        dayjs(timeFilter.value).isSame(dayjs().startOf('day'), 'day')
      ) {
        newQuickFilters.time = 'upcoming';
      } else if (
        timeFilter.operator === FilterConditionOperatorEnum.LessThanEquals &&
        dayjs(timeFilter.value).isSame(dayjs().startOf('day'), 'day')
      ) {
        newQuickFilters.time = 'past';
      } else {
        newQuickFilters.time = 'all';
      }
    }

    // Initialize 'status' quick filter
    if (statusFilter) {
      newQuickFilters.status = statusFilter.value || 'all';
    }

    setQuickFilters(newQuickFilters);
  };

  useEffect(() => {
    handleRefetchExperiences();
  }, [filters, sorting, pagination, searchTerm]);

  useEffect(() => {
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
    initializeQuickFilters();
  }, [filters]);

  return (
    <BasicLayout title={'Experiences'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <Input
          onChange={(event) => debouncedSearch(event.target.value)}
          className={'w-[300px] rounded-none'}
          placeholder={'Search name, phone, or email'}
          inputSize={'sm'}
          fullWidth={false}
        />
        <span className={'flex-1'} />
        <Select
          inputSize={'xs'}
          fullWidth={false}
          value={quickFilters.time}
          onChange={handleQuickFilters}
          name={'time'}
        >
          <option value={'all'}>All Time</option>
          <option value={'upcoming'}>Upcoming</option>
          <option value={'past'}>Past</option>
          <option disabled={true} value={'custom'}>
            Custom
          </option>
        </Select>
        <Select
          inputSize={'xs'}
          fullWidth={false}
          value={quickFilters.status}
          onChange={handleQuickFilters}
          name={'status'}
        >
          <option value={'all'}>All Status</option>
          {Object.values(ExperienceStatusEnum).map((status) => (
            <option value={status} key={status}>
              {status}
            </option>
          ))}
        </Select>
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
        <Button
          size={'xs'}
          color={'success'}
          className={'ml-2'}
          onClick={() => setShowEditExperienceModal(true)}
        >
          New Experience
        </Button>
      </div>

      <BaseTable
        data={experiences}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        noRecordsMessage={'No experiences found'}
        onSortingChange={setSorting}
        onRowClick={(record) => setSelectedExperienceId(record.id)}
      />

      <ExperienceDetailsSideDrawer
        experienceId={selectedExperienceId}
        onClose={() => setSelectedExperienceId('')}
      />
      <UpsertExperienceModal
        experienceId={editExperienceId}
        onClose={handleEditModalClose}
        isOpen={showEditExperienceModal}
      />
    </BasicLayout>
  );
};
