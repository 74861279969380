import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  Broadcast,
  FilterConditionOperatorEnum,
  useDeleteBroadcastMutation,
  useGetBroadcastLazyQuery,
  useGetExperienceAttendanceQuery,
  useGetExperienceQuery,
  useGetExperiencesLazyQuery,
  useUpsertBroadcastMutation,
} from '@/__generated__/graphql';
import Popover from '@/components/Popover';
import Button from '@/components/basic/buttons/Button';
import Autocomplete from '@/components/basic/inputs/Autocomplete';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import Textarea from '@/components/basic/inputs/Textarea';
import { ModalBase } from '@/components/modals/ModalBase';
import { BroadcastDeliveryMethodEnum, BroadcastStatusEnum } from '@/shared/enums';
import { removeInvalidFilters, toTitleCase } from '@/shared/utils/utils';

type Props = {
  selectedBroadcastId?: string;
  isOpen: boolean;
  onClose: (refetch?: boolean) => void;
};

const defaultForm = {
  id: undefined,
  title: '',
  body: '',
  sendAt: '',
  experienceId: '',
  status: BroadcastStatusEnum.draft,
  deliveryMethod: BroadcastDeliveryMethodEnum.sms,
};

export const UpsertBroadcastModal = ({ selectedBroadcastId, isOpen, onClose }: Props) => {
  const [fetchExperiences, { data: experiencesRes, loading: loadingExperiences }] =
    useGetExperiencesLazyQuery();
  const [deleteBroadcast, { loading: deleteLoading, error: deleteError }] =
    useDeleteBroadcastMutation();
  const [experienceSearch, setExperienceSearch] = useState('');
  const experiences = experiencesRes?.getExperiences.experiences || [];
  const [form, setForm] = useState<Partial<Broadcast>>({ ...defaultForm });
  const [upsertBroadcast, { loading: createLoading, error: createError }] =
    useUpsertBroadcastMutation();
  const errors = createError || deleteError;
  const loading = createLoading;
  const [getBroadcast, { data }] = useGetBroadcastLazyQuery();
  const originalBroadcast = data?.getBroadcast;
  const sentInThePast =
    selectedBroadcastId &&
    originalBroadcast?.id &&
    originalBroadcast?.sendAt &&
    dayjs(originalBroadcast?.sendAt).isBefore(dayjs()) &&
    originalBroadcast?.status === BroadcastStatusEnum.sent;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await upsertBroadcast({
        variables: {
          input: {
            id: form?.id,
            title: form.title!,
            body: form.body!,
            sendAt: form.sendAt,
            experienceId: form.experienceId!,
            status: form.status!,
            deliveryMethod: form.deliveryMethod!,
          },
        },
      });

      enqueueSnackbar('Broadcast saved', { variant: 'success' });
      onClose(true);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === 'sendAt') {
      value = dayjs(value).format();
    }

    setForm({ ...form, [name]: value });
  };

  const handleDelete = async () => {
    if (!selectedBroadcastId) {
      return;
    }

    try {
      await deleteBroadcast({
        variables: {
          id: selectedBroadcastId,
        },
      });
      enqueueSnackbar('Broadcast deleted', { variant: 'success' });
      onClose(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (selectedBroadcastId) {
      (async () => {
        const { data } = await getBroadcast({ variables: { id: selectedBroadcastId } });
        const broadcast = data?.getBroadcast;
        if (broadcast) {
          setForm({
            id: broadcast.id,
            title: broadcast.title,
            body: broadcast.body,
            sendAt: broadcast.sendAt,
            experienceId: broadcast.experienceId,
            status: broadcast.status,
            deliveryMethod: broadcast.deliveryMethod,
          });
        } else {
          setForm({ ...defaultForm });
        }
      })();
    } else {
      setForm({ ...defaultForm });
    }

    return () => {
      setForm({ ...defaultForm });
    };
  }, [selectedBroadcastId, isOpen]);

  useEffect(() => {
    fetchExperiences({
      variables: {
        sort: {
          id: 'title',
          order: 'ASC',
        },
        pagination: {
          limit: 20,
          offset: 0,
        },
        filters: removeInvalidFilters([
          {
            field: 'search',
            value: experienceSearch,
            operator: FilterConditionOperatorEnum.Contains,
          },
        ]),
      },
    });
  }, [experienceSearch]);

  return (
    <ModalBase
      title={selectedBroadcastId ? 'Update Broadcast' : 'Create Broadcast'}
      isOpen={isOpen}
      onClose={onClose}
      size={'md'}
    >
      <form onSubmit={handleSubmit} className={'flex w-full flex-col gap-2'}>
        <div className={'grid grid-cols-2 gap-2'}>
          <Input
            required={true}
            label={'Title'}
            value={form.title}
            onChange={handleChange}
            name={'title'}
            disabled={sentInThePast}
          />
          <Select
            required={true}
            label={'Status'}
            value={form.status}
            onChange={handleChange}
            name={'status'}
            disabled={sentInThePast}
            labelAction={
              <Popover
                trigger={<InformationCircleIcon className={'h-4 w-4'} />}
                content={
                  <p className={'max-w-[250px] text-sm'}>
                    Broadcasts can only be sent from "scheduled". The delivery job runs every 5
                    minutes. If a message is not being delivered, please just wait 10 minutes and
                    refresh the page.
                  </p>
                }
              />
            }
          >
            {Object.values(BroadcastStatusEnum).map((status) => {
              return (
                <option key={status} value={status}>
                  {toTitleCase(status)}
                </option>
              );
            })}
          </Select>

          <Select
            required={true}
            label={'Delivery Method'}
            value={form.deliveryMethod}
            onChange={handleChange}
            name={'deliveryMethod'}
            disabled={sentInThePast}
          >
            {Object.values(BroadcastDeliveryMethodEnum).map((method) => {
              return (
                <option key={method} value={method}>
                  {toTitleCase(method)}
                </option>
              );
            })}
          </Select>

          <Input
            type={'datetime-local'}
            required={true}
            label={'Send At'}
            value={form.sendAt ? dayjs(form.sendAt).format('YYYY-MM-DDTHH:mm') : ''}
            onChange={handleChange}
            name={'sendAt'}
            hint={`Local Time: ${dayjs().format('z') || 'UTC'}`}
            min={dayjs().format('YYYY-MM-DDTHH:mm')}
            disabled={sentInThePast}
          />
        </div>

        {form.experienceId ? (
          <SelectedExperience
            experienceId={form.experienceId}
            onRemove={() => setForm({ ...form, experienceId: '' })}
            removable={!sentInThePast}
          />
        ) : (
          <Autocomplete
            loading={loadingExperiences}
            label={'Experience'}
            required={true}
            placeholder={'Select Experience'}
            items={experiences}
            name={'experienceId'}
            renderItem={(experience) => (
              <div className={'p-1'}>
                <h4 className={'text-md'}>{experience.title}</h4>
                <p className={'flex items-center justify-between text-xs text-gray-600'}>
                  <span>{experience?.city}</span>
                  <span>{dayjs(experience.date).format('MMM DD YYYY, h:mm a z')}</span>
                </p>
              </div>
            )}
            itemToString={(experience) => (experience ? `${experience.title}` : '')}
            itemIdentifierKey={'id'}
            onInputValueChange={(input) => {
              setExperienceSearch(input);
            }}
            onSelectedItemChange={(item) => {
              if (item?.id === form.experienceId) return;
              return setForm({ ...form, experienceId: item?.id });
            }}
            initialInputValue={form?.experience?.title || ''}
          />
        )}

        <Textarea
          required={true}
          label={'Body'}
          value={form.body}
          onChange={handleChange}
          name={'body'}
          className={'min-h-20'}
          autoResize={true}
          disabled={sentInThePast}
        />

        {(errors?.message || originalBroadcast?.error) && (
          <p className={'w-full text-center text-xs text-error'}>
            {errors?.message || originalBroadcast?.error}
          </p>
        )}

        <div className={'mt-4 flex gap-4'}>
          {selectedBroadcastId && !sentInThePast ? (
            <Button
              disabled={loading}
              loading={deleteLoading}
              onClick={handleDelete}
              size={'sm'}
              color={'error'}
            >
              Delete
            </Button>
          ) : null}
          <span className={'flex-1'} />
          {!sentInThePast && (
            <Button
              disabled={deleteLoading}
              size={'sm'}
              color={'success'}
              type={'submit'}
              loading={loading}
            >
              Save
            </Button>
          )}
        </div>

        {sentInThePast && (
          <div
            className={`text-center ${originalBroadcast?.error ? 'text-error' : 'text-success'} `}
          >
            {originalBroadcast?.error ? 'Error sending broadcast' : 'Broadcast sent'}
          </div>
        )}
      </form>
    </ModalBase>
  );
};

const SelectedExperience = ({
  experienceId,
  onRemove,
  removable = true,
}: {
  experienceId: string;
  onRemove: () => void;
  removable?: boolean;
}) => {
  const { data, loading } = useGetExperienceQuery({ variables: { id: experienceId } });
  const experience = data?.getExperience;
  const { data: dataAttendance } = useGetExperienceAttendanceQuery({
    variables: { experienceId: experienceId },
  });
  const attendance = dataAttendance?.getExperienceAttendance;

  return (
    <div className={'flex flex-col border p-4'}>
      {loading && <div className={'loading loading-md'} />}
      <div className={'flex items-center justify-between'}>
        <h3 className={'text-lg text-black'}>{experience?.title}</h3>
        {removable && (
          <Button size={'xs'} color={'ghost'} onClick={onRemove}>
            <XMarkIcon className={'h-4 w-4'} aria-hidden={true} />
          </Button>
        )}
      </div>
      <div className={'flex justify-between text-xs text-gray-600'}>
        <span>{experience?.city}</span>
        <span>Att: {attendance?.length}</span>
        <span>
          {experience?.date ? dayjs(experience.date).format('MMM DD YYYY, h:mm a z') : ''}
        </span>
      </div>
    </div>
  );
};
