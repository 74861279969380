import { RolesEnum } from '@/shared/enums';

export const pageRoles = {
  Dashboard: [],
  UserManagement: [
    RolesEnum.interviewerAdmin,
    RolesEnum.customerSuccessAdmin,
    RolesEnum.cityManagerAdmin,
  ],
  InterviewQueue: [
    RolesEnum.interviewerAdmin,
    RolesEnum.interviewerApprover,
    RolesEnum.interviewer,
  ],
  Interviews: [RolesEnum.interviewer, RolesEnum.interviewerAdmin, RolesEnum.interviewerApprover],
  FinishedInterviews: [RolesEnum.interviewerAdmin, RolesEnum.interviewerApprover],
  InterviewFollowUp: [
    RolesEnum.interviewerAdmin,
    RolesEnum.interviewerApprover,
    RolesEnum.interviewer,
  ],
  Experiences: [
    RolesEnum.customerSuccess,
    RolesEnum.customerSuccessAdmin,
    RolesEnum.cityManager,
    RolesEnum.cityManagerAdmin,
  ],
  Topics: [
    RolesEnum.customerSuccess,
    RolesEnum.customerSuccessAdmin,
    RolesEnum.cityManager,
    RolesEnum.cityManagerAdmin,
  ],
  Locations: [
    RolesEnum.customerSuccess,
    RolesEnum.customerSuccessAdmin,
    RolesEnum.cityManager,
    RolesEnum.cityManagerAdmin,
  ],
  Dinners: [
    RolesEnum.customerSuccess,
    RolesEnum.customerSuccessAdmin,
    RolesEnum.cityManager,
    RolesEnum.cityManagerAdmin,
  ],
  Person: [
    RolesEnum.customerSuccess,
    RolesEnum.customerSuccessAdmin,
    RolesEnum.interviewerAdmin,
    RolesEnum.cityManager,
    RolesEnum.cityManagerAdmin,
  ],
  Broadcasts: [RolesEnum.cityManager, RolesEnum.cityManagerAdmin],
};
