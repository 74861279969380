import { InformationCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetBroadcastsQuery,
  useGetBroadcastsQuery,
} from '@/__generated__/graphql';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import BaseTable from '@/components/baseTable/BaseTable';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { BadgeCell } from '@/components/tables/tanstackComponents/BadgeCell';
import { UpsertBroadcastModal } from '@/pages/Broadcasts/components/UpsertBroadcastModal';
import { BroadcastStatusEnum, CityEnum } from '@/shared/enums';
import { removeInvalidFilters } from '@/shared/utils/utils';

export const BroadcastsPage = () => {
  const FILTERS_STORAGE_KEY = 'Broadcasts_filters';
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FilterInput[]>(() => {
    // Load initial filters from local storage if available
    const storedFilters = localStorage.getItem(FILTERS_STORAGE_KEY);
    return storedFilters ? JSON.parse(storedFilters) : [];
  });
  const [searchParams] = useSearchParams();
  const debouncedSearch = useRef(
    debounce(async (criteria: string) => {
      setSearchTerm(criteria);
    }, 300),
  ).current;
  const [showEditModal, setShowEditModal] = useState(false);
  const {
    data: broadcastsRes,
    loading,
    refetch: broadcastsRefetch,
  } = useGetBroadcastsQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const broadcasts = broadcastsRes?.getBroadcasts.broadcasts || [];
  const totalRecords = broadcastsRes?.getBroadcasts.count;
  const [selectedBroadcastId, setSelectedBroadcastId] = useState(searchParams.get('e') || '');
  const [quickFilters, setQuickFilters] = useState<Record<string, string>>({
    time: 'all',
    status: 'all',
    city: 'all',
  });

  const columns: ColumnDef<GetBroadcastsQuery['getBroadcasts']['broadcasts'][0]>[] = [
    {
      id: 'action',
      accessorFn: (record) => record.id,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          <Button
            color={'ghost'}
            size={'xs'}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedBroadcastId(cell.getValue() as string);
              setShowEditModal(true);
            }}
          >
            <PencilIcon className={'w-4'} />
          </Button>
        </div>
      ),
      enableSorting: false,
      header: 'Edit',
    },
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'title',
      header: 'Title',
      cell: ({ cell }) => (
        <div
          className={'min-w-full max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap'}
          title={cell.getValue() as any}
        >
          {cell.getValue() as any}
        </div>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: BadgeCell,
    },
    {
      accessorKey: 'sendAt',
      header: 'Send At',
      accessorFn: (record) =>
        record.sendAt ? dayjs(record.sendAt).format('MMM D, YYYY h:mm A z') : '',
    },
    {
      accessorFn: (record) => record?.experience?.title,
      header: 'Experience',
      enableSorting: false,
    },
    {
      accessorFn: (record) => record?.experience?.location?.cityRef?.name,
      accessorKey: 'city',
      header: 'City',
      enableSorting: false,
      cell: BadgeCell,
    },
    {
      accessorFn: (record) => record.body,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          {<InformationCircleIcon title={cell.getValue() as any} className={'w-4'} />}
        </div>
      ),
      enableSorting: false,
      header: 'Body',
    },
  ];

  const getFilters = (): IFilter[] => {
    return [
      {
        field: 'sendAt',
        type: 'date',
        operations: [FilterConditionOperatorEnum.GreaterThan, FilterConditionOperatorEnum.LessThan],
      },
      {
        field: 'status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(BroadcastStatusEnum),
      },
      {
        field: 'city',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(CityEnum),
      },
    ];
  };

  const handleRefetchBroadcasts = async () => {
    const tempFilters = [
      ...filters,
      {
        field: 'search',
        value: searchTerm,
        operator: FilterConditionOperatorEnum.Contains,
      },
    ];

    await broadcastsRefetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(tempFilters),
    });
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedBroadcastId('');
    handleRefetchBroadcasts();
  };

  const handleQuickFiltersChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuickFilters({
      ...quickFilters,
      [name]: value,
    });

    if (name === 'time') {
      const dateFilter: FilterInput = filters.find((filter) => filter.field === 'sendAt') || {
        field: 'sendAt',
        operator: FilterConditionOperatorEnum.GreaterThan,
        value: '',
      };
      const newFilters = filters.filter((filter) => filter.field !== 'sendAt');
      if (value === 'all') {
        setFilters(newFilters);
      } else if (value === 'upcoming') {
        dateFilter.operator = FilterConditionOperatorEnum.GreaterThanEquals;
        dateFilter.value = dayjs().startOf('day').toString();
        newFilters.push(dateFilter);
        setFilters(newFilters);
      } else if (value === 'past') {
        dateFilter.operator = FilterConditionOperatorEnum.LessThanEquals;
        dateFilter.value = dayjs().startOf('day').toString();
        newFilters.push(dateFilter);
        setFilters(newFilters);
      }
    } else if (name === 'status') {
      const statusFilter: FilterInput = filters.find((filter) => filter.field === 'status') || {
        field: 'status',
        operator: FilterConditionOperatorEnum.Equals,
        value: '',
      };
      const newFilters = filters.filter((filter) => filter.field !== 'status');
      if (value === 'all') {
        setFilters(newFilters);
      } else {
        statusFilter.value = value;
        newFilters.push(statusFilter);
        setFilters(newFilters);
      }
    } else if (name === 'city') {
      const cityFilter: FilterInput = filters.find((filter) => filter.field === 'city') || {
        field: 'city',
        operator: FilterConditionOperatorEnum.Equals,
        value: '',
      };
      const newFilters = filters.filter((filter) => filter.field !== 'city');
      if (value === 'all') {
        setFilters(newFilters);
      } else {
        cityFilter.value = value;
        newFilters.push(cityFilter);
        setFilters(newFilters);
      }
    }
  };

  // Function to update quickFilters based on the provided filters array
  const updateQuickFilters = () => {
    const timeFilter = filters.find((filter) => filter.field === 'sendAt');
    const statusFilter = filters.find((filter) => filter.field === 'status');
    const cityFilter = filters.find((filter) => filter.field === 'city');

    const newQuickFilters = { ...quickFilters };

    // Initialize 'time' quick filter
    if (timeFilter) {
      if (
        timeFilter.operator === FilterConditionOperatorEnum.GreaterThanEquals &&
        dayjs(timeFilter.value).isSame(dayjs().startOf('day'), 'day')
      ) {
        newQuickFilters.time = 'upcoming';
      } else if (
        timeFilter.operator === FilterConditionOperatorEnum.LessThanEquals &&
        dayjs(timeFilter.value).isSame(dayjs().startOf('day'), 'day')
      ) {
        newQuickFilters.time = 'past';
      } else {
        newQuickFilters.time = 'all';
      }
    } else {
      newQuickFilters.time = 'all';
    }

    // Initialize 'status' quick filter
    if (statusFilter) {
      newQuickFilters.status = statusFilter.value || 'all';
    } else {
      newQuickFilters.status = 'all';
    }

    // Initialize 'city' quick filter
    if (cityFilter) {
      newQuickFilters.city = cityFilter.value || 'all';
    } else {
      newQuickFilters.city = 'all';
    }

    setQuickFilters(newQuickFilters);
  };

  useEffect(() => {
    handleRefetchBroadcasts();
  }, [filters, sorting, pagination, searchTerm]);

  useEffect(() => {
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
    updateQuickFilters();
  }, [filters]);

  return (
    <BasicLayout title={'Broadcasts'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <Input
          onChange={(event) => debouncedSearch(event.target.value)}
          className={'w-[300px] rounded-none'}
          placeholder={'Search title, body, experience'}
          inputSize={'sm'}
          fullWidth={false}
        />
        <span className={'flex-1'} />
        <Select
          inputSize={'xs'}
          fullWidth={false}
          value={quickFilters.time}
          onChange={handleQuickFiltersChange}
          name={'time'}
        >
          <option value={'all'}>All Time</option>
          <option value={'upcoming'}>Upcoming</option>
          <option value={'past'}>Past</option>
          <option disabled={true} value={'custom'}>
            Custom
          </option>
        </Select>
        <Select
          inputSize={'xs'}
          fullWidth={false}
          value={quickFilters.status}
          onChange={handleQuickFiltersChange}
          name={'status'}
        >
          <option value={'all'}>All Status</option>
          {Object.values(BroadcastStatusEnum).map((status) => (
            <option value={status} key={status}>
              {status}
            </option>
          ))}
        </Select>
        <Select
          inputSize={'xs'}
          fullWidth={false}
          value={quickFilters.status}
          onChange={handleQuickFiltersChange}
          name={'city'}
        >
          <option value={'all'}>All Cities</option>
          {Object.values(CityEnum).map((city) => (
            <option value={city} key={city}>
              {city}
            </option>
          ))}
        </Select>
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
        <Button
          size={'xs'}
          color={'success'}
          className={'ml-2'}
          onClick={() => setShowEditModal(true)}
        >
          New Broadcast
        </Button>
      </div>

      <BaseTable
        data={broadcasts}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        noRecordsMessage={'No broadcasts found'}
        onSortingChange={setSorting}
      />

      <UpsertBroadcastModal
        selectedBroadcastId={selectedBroadcastId}
        onClose={handleEditModalClose}
        isOpen={showEditModal}
      />
    </BasicLayout>
  );
};
