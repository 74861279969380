import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { useGetPersonLazyQuery, useUpdateMembershipStatusMutation } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Textarea from '@/components/basic/inputs/Textarea';
import { ModalBase } from '@/components/modals/ModalBase';
import { PersonMembershipStatusEnum, PersonsStatusEnum } from '@/shared/enums';

type Props = {
  personId: string;
  membershipStatus: string;
  matchAfterDate?: string;
};

export const PauseAccountTrigger = ({ personId, membershipStatus, matchAfterDate }: Props) => {
  const [open, setOpen] = useState(false);
  const [getPerson, { loading: personLoading }] = useGetPersonLazyQuery();
  const [updateMembershipStatus, { loading: updateLoading }] = useUpdateMembershipStatusMutation();
  const isPaused = membershipStatus === PersonsStatusEnum.Paused;
  const [newMatchAfterDate, setNewMatchAfterDate] = useState<string>(
    isPaused && matchAfterDate ? dayjs(matchAfterDate).format('YYYY-MM-DD') : '',
  );
  const [pauseReason, setPauseReason] = useState<string>('');
  const loading = updateLoading || personLoading;

  const handleClose = () => {
    setOpen(false);
  };

  const handlePause = async (e) => {
    e.preventDefault();

    if (!newMatchAfterDate) {
      enqueueSnackbar('Please select match after date.');
      return;
    }

    try {
      await updateMembershipStatus({
        variables: {
          id: personId,
          status: PersonMembershipStatusEnum.Paused,
          payload: {
            matchAfterDate: dayjs(newMatchAfterDate).format(),
            reason: pauseReason,
          },
        },
      });
      await getPerson({
        variables: { id: personId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      });

      enqueueSnackbar(`Account paused ${isPaused ? 'updated ' : ''}successfully.`, {
        variant: 'success',
      });

      handleClose();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleUnpause = async (e) => {
    e.preventDefault();

    try {
      await updateMembershipStatus({
        variables: {
          id: personId,
          status: PersonMembershipStatusEnum.Active,
          payload: {},
        },
      });
      await getPerson({
        variables: { id: personId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      });

      enqueueSnackbar(`Account unpaused successfully.`, {
        variant: 'success',
      });
      handleClose();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant={isPaused ? undefined : 'outline'}
        color={isPaused ? 'warning' : 'neutral'}
        size={'xs'}
      >
        {isPaused ? 'Update Paused Account ' : 'Pause Account'}
      </Button>
      <ModalBase
        isOpen={open}
        title={isPaused ? 'Update Paused Account ' : 'Pause Account'}
        onClose={handleClose}
      >
        {!isPaused && <p className={'mb-4'}>Are you sure you want to pause this account?</p>}
        <form className={'flex flex-col gap-4'}>
          <Input
            label={'Match After Date'}
            type={'date'}
            required={true}
            onChange={(e) => setNewMatchAfterDate(e.target.value)}
            value={newMatchAfterDate}
            min={dayjs().format('YYYY-MM-DD')}
          />
          {!isPaused && (
            <Textarea
              label={'Reason'}
              required={true}
              onChange={(e) => setPauseReason(e.target.value)}
              value={pauseReason}
              className={'min-h-20'}
            />
          )}
          <div className={'mt-5 flex justify-end gap-2'}>
            {isPaused && (
              <Button disabled={loading} onClick={handleUnpause} color={'success'} size={'xs'}>
                Unpause Account
              </Button>
            )}

            <span className={'flex-1'} />

            <Button disabled={loading} loading={loading} onClick={handlePause} size={'xs'}>
              {isPaused ? 'Update Pause' : 'Pause Account'}
            </Button>
          </div>
        </form>
      </ModalBase>
    </>
  );
};
