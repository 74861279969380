import React from 'react';
import { twMerge } from 'tailwind-merge';

import { InputLabel } from '@/components/basic/inputs/InputLabel';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  color?: 'primary' | 'secondary' | 'accent' | 'info' | 'neutral';
  inputSize?: 'xs' | 'sm' | 'md' | 'lg';
  label?: string;
  labelClassName?: HTMLLabelElement['className'];
  hint?: string | React.ReactNode;
  fullWidth?: boolean;
}

const Input: React.FC<InputProps> = ({
  color = 'info',
  inputSize = 'sm',
  className = '',
  label,
  required = false,
  labelClassName,
  hint,
  fullWidth = true,
  ...props
}) => {
  const baseClass = 'input border-gray-400';
  const variantClass = `input-${color}`;
  const sizeClass = `input-${inputSize}`;

  return (
    <div className={`flex ${fullWidth ? 'w-full' : 'w-max'} flex-col gap-1`}>
      {label && <InputLabel label={label} required={required} className={labelClassName} />}

      <input
        className={twMerge(
          `${baseClass} ${variantClass} ${sizeClass} w-full focus:border-2 focus:outline-0`,
          className,
        )}
        {...props}
        required={required}
      />
      {hint && <p className={'text-right text-xs'}>{hint}</p>}
    </div>
  );
};

export default Input;
