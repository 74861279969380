import { supabase } from '@/modules/supabase';

export const getSupabaseImageUrl = async (
  bucketName: string,
  path: string,
): Promise<string | null> => {
  const { data } = supabase.storage.from(bucketName).getPublicUrl(path);

  if (data.publicUrl) {
    return data.publicUrl;
  }

  return null;
};

export const uploadFile = async (url: string, token: string, file: File) => {
  const response = await fetch(url, {
    method: 'PUT', // Supabase expects PUT
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': file.type, // Ensure correct MIME type
    },
    body: file, // Send the file directly
  });

  if (!response.ok) {
    throw new Error(`Failed to upload file to supabase: ${response.statusText}`);
  }
};
