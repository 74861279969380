import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  useGetPersonExperienceTransactionsLazyQuery,
  useRefundPersonExperienceMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Checkbox from '@/components/basic/inputs/Checkbox';
import Input from '@/components/basic/inputs/Input';
import Textarea from '@/components/basic/inputs/Textarea';
import { ModalBase } from '@/components/modals/ModalBase';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  personId: string;
  experienceId: string;
};

const defaultForm: { reason: string; removeFromExperience: boolean; amount?: number | string } = {
  reason: '',
  amount: '',
  removeFromExperience: true,
};

export const RefundPersonExperienceModal = ({ onClose, isOpen, experienceId, personId }: Props) => {
  const [fetchTransactions] = useGetPersonExperienceTransactionsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const [refundPerson, { error, loading }] = useRefundPersonExperienceMutation();
  const [totalRefund, setTotalRefund] = useState('0');
  const [form, setForm] = useState({ ...defaultForm });

  const handleFetchTransactions = async () => {
    const data = await fetchTransactions({
      variables: {
        personId,
        experienceId,
      },
    });

    const amount =
      data.data?.getPersonExperienceTransactions.reduce((acc, transaction) => {
        if (transaction.stripeAmount && transaction.stripeStatus === 'succeeded') {
          return acc + transaction.stripeAmount;
        }
        return acc;
      }, 0) || 0;

    const totalRefunded =
      data.data?.getPersonExperienceTransactions.reduce((acc, transaction) => {
        if (transaction.stripeAmount && transaction.stripeStatus === 'succeeded') {
          return acc + transaction.stripeRefundAmount;
        }
        return acc;
      }, 0) || 0;

    setTotalRefund(((amount - totalRefunded) / 100).toFixed(2));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const amount_in_cents = Math.floor(parseFloat(`${form.amount}` || '0') * 100);

    try {
      await refundPerson({
        variables: {
          experienceId,
          personId,
          amount: amount_in_cents,
          reason: form.reason,
          removeFromExperience: form.removeFromExperience,
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error refunding experience', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isOpen && experienceId && personId) {
      handleFetchTransactions();
    }

    return () => {
      setForm({ ...defaultForm });
    };
  }, [isOpen, experienceId, personId]);

  return (
    <ModalBase title={'Refund Person'} isOpen={isOpen} onClose={onClose}>
      <p className={'my-4 text-center text-neutral/60'}>
        <b>Refundable Amount:</b> ${totalRefund}
      </p>

      <form className={'flex flex-col'} onSubmit={handleSubmit}>
        <Input
          label={'Amount'}
          inputSize={'sm'}
          required={true}
          value={form.amount}
          type={'number'}
          min={0}
          max={parseFloat(totalRefund)}
          onChange={(e) => {
            let value = parseFloat(e.target.value);
            if (!isNaN(value)) {
              // Limit to 2 decimal points
              value = Math.floor(value * 100) / 100;
            }
            setForm({ ...form, amount: isNaN(value) ? '' : value });
          }}
          hint={
            <Button
              color={'neutral'}
              variant={'link'}
              size={'xs'}
              disabled={form.amount === totalRefund}
              onClick={() => setForm({ ...form, amount: totalRefund })}
            >
              Full Amount
            </Button>
          }
        />

        <Textarea
          className={'mb-4 max-h-36'}
          inputSize={'xs'}
          required={form.amount !== 0 && form.amount !== '0.00'}
          autoResize={true}
          label={'Reason'}
          value={form.reason}
          onChange={(e) => setForm({ ...form, reason: e.target.value })}
        />

        <Checkbox
          horizontal={true}
          color={'neutral'}
          label={'Remove from Experience?'}
          name={'removeFromEvents'}
          checked={form.removeFromExperience}
          onChange={() => setForm({ ...form, removeFromExperience: !form.removeFromExperience })}
        />

        {error && <p className={'mt-2 text-center text-xs text-error'}>{error.message}</p>}

        <div className={'mt-4 flex items-center'}>
          <span className={'flex-1'} />
          <Button color={'success'} size={'xs'} type={'submit'} loading={loading}>
            Submit
          </Button>
        </div>
      </form>
    </ModalBase>
  );
};
