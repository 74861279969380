import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import {
  Person,
  useGetPersonLazyQuery,
  useUpdateMembershipStatusMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Checkbox from '@/components/basic/inputs/Checkbox';
import Textarea from '@/components/basic/inputs/Textarea';
import { ModalBase } from '@/components/modals/ModalBase';
import { PersonMembershipStatusEnum } from '@/shared/enums';

type Props = {
  person: Person;
  membershipStatus: string;
};

export const CancelAccountTrigger = ({ person, membershipStatus }: Props) => {
  const [open, setOpen] = useState(false);
  const [getPerson, { loading: personLoading }] = useGetPersonLazyQuery();
  const [updateMembershipStatus, { loading: updateLoading }] = useUpdateMembershipStatusMutation();
  const isCanceled = membershipStatus === PersonMembershipStatusEnum.Canceled;
  const [cancelReason, setCancelReason] = useState<string>('');
  const [cancelImmediately, setCancelImmediately] = useState(false);
  const loading = updateLoading || personLoading;

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = async (e) => {
    e.preventDefault();

    try {
      await updateMembershipStatus({
        variables: {
          id: person.id,
          status: PersonMembershipStatusEnum.Canceled,
          payload: {
            reason: cancelReason,
            cancelImmediately,
          },
        },
      });
      await getPerson({
        variables: { id: person.id },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      });

      enqueueSnackbar(`Account canceled successfully.`, {
        variant: 'success',
      });

      handleClose();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant={isCanceled ? undefined : 'outline'}
        color={'error'}
        size={'xs'}
      >
        {'Cancel Account'}
      </Button>
      <ModalBase isOpen={open} title={'Cancel Account'} onClose={handleClose}>
        <p className={'mb-4'}>Are you sure you want to cancel this account?</p>

        {!cancelImmediately && person.subscription?.currentPeriodEnd ? (
          <p className={'text-center text-sm underline'}>
            Will cancel on{' '}
            <b>{dayjs(person.subscription?.currentPeriodEnd).format('MMM DD YYYY')}</b>
          </p>
        ) : null}

        <form className={'flex flex-col gap-4'}>
          <Textarea
            label={'Reason'}
            onChange={(e) => setCancelReason(e.target.value)}
            value={cancelReason}
            className={'min-h-10'}
          />

          <Checkbox
            className={'border-black'}
            horizontal={true}
            label={'Cancel Immediately?'}
            checked={cancelImmediately}
            onChange={(e) => setCancelImmediately(e.target.checked)}
          />

          <div className={'mt-0 flex justify-end gap-2'}>
            <span className={'flex-1'} />
            <Button disabled={loading} loading={loading} onClick={handleCancel} size={'xs'}>
              {'Cancel Account'}
            </Button>
          </div>
        </form>
      </ModalBase>
    </>
  );
};
