import { CellContext, RowData } from '@tanstack/react-table';

import { shouldTextBeWhite, stringToColor } from '@/shared/utils/stringToColorUtils';
import { toTitleCase } from '@/shared/utils/utils';

export const BadgeCell = <TData extends RowData, TValue = unknown>({
  cell,
}: CellContext<TData, TValue>) => {
  const value = cell.getValue() as string;

  if (!value) {
    return '';
  }

  return (
    <span
      className={`badge ${shouldTextBeWhite(stringToColor(value)) ? 'text-white' : 'text-black'}`}
      style={{ background: stringToColor(value) }}
    >
      {toTitleCase(value)}
    </span>
  );
};
