import { ChangeEvent, useRef } from 'react';

import Button, { ButtonProps } from '@/components/basic/buttons/Button';

interface Props extends ButtonProps {
  onFileUpload: (file: File) => void; // Callback for handling the uploaded file
  buttonText?: string; // Optional button text
  maxFileSizeMb?: number; // Max file size in megabytes (optional)
}

export const FileUploadButton = ({
  onFileUpload,
  buttonText = 'Upload File', // Default button text
  maxFileSizeMb = 50, // Max file size in MB, defaults 50mb
  ...otherProps
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    // Trigger the hidden file input click
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the first selected file
    if (file) {
      // Check if file size exceeds the max size (if provided)
      if (maxFileSizeMb && file.size > maxFileSizeMb * 1024 * 1024) {
        alert(`File size exceeds the maximum limit of ${maxFileSizeMb} MB.`);
      } else {
        onFileUpload(file); // Invoke callback with the valid file
      }
    }
    event.target.value = ''; // Reset the input to allow re-uploading the same file
  };

  return (
    <>
      <Button onClick={handleButtonClick} {...otherProps}>
        {buttonText}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Input is hidden
      />
    </>
  );
};
