import { Person } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';

type Props = {
  person: Person;
};

export const ApplicationDetails = ({ person }: Props) => {
  const details = [
    {
      title: 'Membership Type',
      value: person?.membershipChargePeriod,
    },
    {
      title: 'Archtype',
      value: person?.archetype,
    },
    {
      title: 'Work',
      value: person?.workDescription,
    },
    {
      title: 'Industry',
      value: person?.industry,
    },
    {
      title: 'Area of Study',
      value: person?.areaOfStudy,
    },
    {
      title: 'Broadcast Persona',
      value: person?.dinnerPersona,
    },
    {
      title: 'Accomplishments',
      value: person?.accomplishments,
    },
    {
      title: '3 Words',
      value: person?.threeWords,
    },
    {
      title: 'Interests',
      value: person?.interests,
    },
    {
      title: 'Broadcast Guest',
      value: person?.applicationDinnerGuest,
    },
    {
      title: 'Nomination code',
      value: person?.nominationCode,
    },
  ];

  return <DetailTableSection title={'Application'} details={details} />;
};
