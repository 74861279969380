import React, { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { InputLabel } from '@/components/basic/inputs/InputLabel';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  color?: 'primary' | 'secondary' | 'accent' | 'info' | 'neutral';
  inputSize?: 'xs' | 'sm' | 'md' | 'lg';
  label?: string;
  labelClassName?: HTMLLabelElement['className'];
  hint?: string;
  fullWidth?: boolean;
  autoResize?: boolean; // Enable auto-resizing
}

const Textarea: React.FC<TextareaProps> = ({
  color = 'info',
  inputSize = 'sm',
  className = '',
  label,
  required = false,
  labelClassName,
  hint,
  fullWidth = true,
  autoResize = false,
  value,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Auto-resize the height based on content
  const resizeTextarea = () => {
    if (autoResize && textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust to fit content
    }
  };

  // Adjust height on content change
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(e); // Call user's custom onChange handler if provided
    }
    resizeTextarea(); // Resize dynamically
  };

  // Ensure resizing works on initial load or when `value` changes
  useEffect(() => {
    resizeTextarea();
  }, [value]);

  const baseClass = 'input border-gray-400';
  const variantClass = `input-${color}`;
  const sizeClass = `input-${inputSize}`;

  return (
    <div className={`flex ${fullWidth ? 'w-full' : 'w-max'} flex-col gap-1`}>
      {label && <InputLabel label={label} required={required} className={labelClassName} />}

      <textarea
        ref={textareaRef}
        className={twMerge(
          `${baseClass} ${variantClass} ${sizeClass} w-full resize-none focus:border-2 focus:outline-0`, // Disable manual resizing
          className,
        )}
        value={value}
        onInput={handleInput} // Trigger auto-resizing on user input
        {...props}
        required={required}
        rows={1} // Ensure initial height is minimal
        style={autoResize ? { overflow: 'hidden' } : {}}
      />
      {hint && <p className={'text-right text-xs'}>{hint}</p>}
    </div>
  );
};

export default Textarea;
