import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  GetExperienceAttendanceQuery,
  GetExperienceQuery,
  useGetExperienceAttendanceQuery,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { ButtonWithDropdown } from '@/components/basic/buttons/ButtonWithDropdown';
import Checkbox from '@/components/basic/inputs/Checkbox';
import { RefundPersonExperienceModal } from '@/components/modals/modals/RefundPersonExperienceModal';
import { SimpleTable, SimpleTableColumn } from '@/components/tables/SimpleTable';
import { useAuth } from '@/hooks/useAuth';
import { RolesEnum } from '@/shared/enums';
import { downloadCSV } from '@/shared/utils/downloadCSV';
import { hasRoleAccess } from '@/shared/utils/rolesUtils';
import { formatPhoneNumber, getStripeUrl } from '@/shared/utils/utils';

type Props = {
  experience: GetExperienceQuery['getExperience'];
};

export const AttendanceTabContent = ({ experience }: Props) => {
  const { user } = useAuth();
  const [showAll, setShowAll] = useState(false);
  const { data, loading, refetch } = useGetExperienceAttendanceQuery({
    variables: { experienceId: experience.id },
  });
  const [selectedPersonToRefund, setSelectedPersonToRefund] = useState('');
  const attendance = data?.getExperienceAttendance || [];

  const columns: SimpleTableColumn<GetExperienceAttendanceQuery['getExperienceAttendance'][0]>[] = [
    {
      headerName: 'Id',
      accessorKey: 'id',
      hide: true,
    },
    {
      headerName: 'Person Name',
      accessorKey: 'personName',
      accessorFunc: (record) => {
        return (
          <Link target={'_blank'} className={'link'} to={`/persons?p=${record?.personId}`}>
            {record?.personName}
          </Link>
        );
      },
      className: 'whitespace-nowrap',
    },
    {
      headerName: 'Email',
      accessorKey: 'personEmail',
    },
    {
      headerName: 'Phone Number',
      accessorKey: 'phoneNumber',
      accessorFunc: (record) => formatPhoneNumber(record.phoneNumber),
      className: 'whitespace-nowrap',
      hide: !hasRoleAccess(user!.roles, [RolesEnum.cityManager]),
    },
    {
      headerName: 'Ticket Count',
      accessorKey: 'ticketCount',
    },
    {
      headerName: 'Stripe Payment ID',
      accessorKey: 'stripePaymentIntentId',
    },
    {
      headerName: 'Dietary Restrictions',
      accessorKey: 'dietaryRestrictions',
    },
    {
      headerName: 'Payment Status',
      accessorKey: 'stripePaymentStatus',
      accessorFunc: (record) => {
        let status = record.stripePaymentStatus;
        if (status === 'refunded' && !record.stripeRefundAmount) {
          status = 'Removed (No refund)';
        }

        return (
          <div className={'flex'}>
            <span className={'block'}>{status}</span>
            <Button
              onClick={() =>
                window.open(`${getStripeUrl()}/payments/${record.stripePaymentIntentId}`)
              }
              color={'ghost'}
              size={'xs'}
              variant={'link'}
            >
              <ArrowUpRightIcon color={'black'} className={'h-4 w-4'} />
            </Button>
          </div>
        );
      },
    },
    {
      headerName: 'Refunded',
      accessorKey: 'stripeRefundAmount',
      accessorFunc: (record) =>
        record.stripeRefundAmount ? `$${(record.stripeRefundAmount / 100).toFixed(2)}` : `$${0}`,
    },
    {
      headerName: '',
      accessorKey: 'actions',
      accessorFunc: (row) => {
        return (
          <>
            {row.stripePaymentStatus === 'succeeded' && (
              <ButtonWithDropdown
                dropdownActions={[
                  {
                    label: 'Remove/Refund',
                    onClick: () => setSelectedPersonToRefund(row.personId),
                  },
                ]}
                size={'xs'}
              >
                Actions
              </ButtonWithDropdown>
            )}
          </>
        );
      },
    },
  ];

  const handleRefundCloseModal = async () => {
    await refetch({ experienceId: experience.id });
    setSelectedPersonToRefund('');
  };

  return (
    <div className={'flex flex-1 flex-col p-4'}>
      <div className={'flex justify-end gap-2 border-b pb-2'}>
        <div className={'flex gap-4'}>
          <Button
            onClick={() =>
              downloadCSV(
                attendance
                  .filter((att) => att.stripePaymentStatus === 'succeeded')
                  .map((att) => {
                    return {
                      ...att,
                      experienceTitle: experience.title,
                      location: experience.location?.name,
                      experienceDate: dayjs(experience.date).format('MMM D, YYYY h:mm a z'),
                      city: experience.location?.cityRef?.name,
                    };
                  }),
                experience.title + ' Attendance.csv',
                ['__typename', 'id', 'stripePaymentIntentId', 'stripePaymentStatus'],
                undefined,
                ['personName', 'dietaryRestrictions'],
              )
            }
            size={'xs'}
          >
            Download
          </Button>
          <Checkbox
            onClick={() => setShowAll(!showAll)}
            horizontal={true}
            className={'border-black'}
            label={'Show All'}
          />
        </div>
      </div>
      <SimpleTable
        data={[...attendance].filter((att) =>
          showAll ? true : att.stripePaymentStatus === 'succeeded',
        )}
        columns={columns}
        loading={loading}
        className={'max-h-[90%] flex-1 overflow-auto border'}
      />

      {experience?.id && (
        <RefundPersonExperienceModal
          personId={selectedPersonToRefund}
          experienceId={experience.id}
          isOpen={Boolean(selectedPersonToRefund)}
          onClose={handleRefundCloseModal}
        />
      )}
    </div>
  );
};
