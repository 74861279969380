import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';

import { pageRoles } from '@/app/pageRoles';
import { NavigationLayout } from '@/components/layouts/NavigationLayout/NavigationLayout';
import { AuthRoute } from '@/components/routes/AuthRoute';
import { RoleRoute } from '@/components/routes/RoleRoute';
import { useAuth } from '@/hooks/useAuth';
import { Page404 } from '@/pages/404Page';
import { AuthPage } from '@/pages/AuthPage/AuthPage';
import { BroadcastsPage } from '@/pages/Broadcasts/BroadcastsPage';
import { DashboardPage } from '@/pages/DashboardPage/DashboardPage';
import { DinnersPage } from '@/pages/DinnersPage/DinnersPage';
import { ErrorBoundaryPage } from '@/pages/ErrorBoundaryPage';
import { ExperiencesPage } from '@/pages/Experiences/ExperiencesPage/ExperiencesPage';
import { TopicsPage } from '@/pages/Experiences/TopicsPage/TopicsPage';
import { InterviewFollowUpPage } from '@/pages/Interviews/InterviewFollowUpPage/InterviewFollowUpPage';
import { InterviewsPage } from '@/pages/Interviews/InterviewsPage/InterviewsPage';
import { InterviewsQueuePage } from '@/pages/Interviews/InterviewsQueuePage/InterviewsQueuePage';
import { PersonsPage } from '@/pages/PersonsPage/PersonsPage';
import { CitiesAndNeighborhoodsPage } from '@/pages/Places/CitiesAndNeighborhoods/CitiesAndNeighborhoodsPage';
import { LocationsPage } from '@/pages/Places/LocationsPage/LocationsPage';
import { UsersPage } from '@/pages/UsersPage/UsersPage';

function App() {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
        roles: user.roles.map((r) => r.name).join(','),
      });
    }
  }, [user]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryPage}
      onError={(error, info) => console.error(error, info)}
      fallback={undefined}
    >
      <Routes>
        <Route path={'/login'} element={<AuthPage />} />
        <Route element={<AuthRoute element={<NavigationLayout />} />}>
          <Route path={'/'} element={<DashboardPage />} />
          <Route
            path={'/users'}
            element={<RoleRoute roles={pageRoles.UserManagement} element={<UsersPage />} />}
          />
          <Route
            path={'/interview-queue'}
            element={
              <RoleRoute element={<InterviewsQueuePage />} roles={pageRoles.InterviewQueue} />
            }
          />
          <Route
            path={'/interviews'}
            element={<RoleRoute element={<InterviewsPage />} roles={pageRoles.InterviewQueue} />}
          />
          <Route
            path={'/finished-interviews'}
            element={
              <RoleRoute element={<InterviewsQueuePage />} roles={pageRoles.FinishedInterviews} />
            }
          />
          <Route
            path={'/interview-followups'}
            element={
              <RoleRoute element={<InterviewFollowUpPage />} roles={pageRoles.InterviewFollowUp} />
            }
          />
          <Route
            path={'/experiences'}
            element={<RoleRoute element={<ExperiencesPage />} roles={pageRoles.Experiences} />}
          />
          <Route
            path={'/dinners'}
            element={<RoleRoute element={<DinnersPage />} roles={pageRoles.Dinners} />}
          />
          <Route
            path={'/broadcasts'}
            element={<RoleRoute element={<BroadcastsPage />} roles={pageRoles.Broadcasts} />}
          />
          <Route
            path={'/persons'}
            element={<RoleRoute element={<PersonsPage />} roles={pageRoles.Person} />}
          />
          <Route
            path={'/experiences-topics'}
            element={<RoleRoute element={<TopicsPage />} roles={pageRoles.Topics} />}
          />
          <Route
            path={'/locations'}
            element={<RoleRoute element={<LocationsPage />} roles={pageRoles.Locations} />}
          />
          <Route
            path={'/cities-neighborhoods'}
            element={
              <RoleRoute element={<CitiesAndNeighborhoodsPage />} roles={pageRoles.Locations} />
            }
          />
        </Route>
        <Route path={'*'} element={<Page404 />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
