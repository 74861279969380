import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { InputLabel } from '@/components/basic/inputs/InputLabel';

interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  color?: 'primary' | 'secondary' | 'accent' | 'info' | 'neutral';
  inputSize?: 'xs' | 'sm' | 'md' | 'lg';
  label?: string;
  labelClassName?: HTMLLabelElement['className'];
  hint?: string;
  fullWidth?: boolean;
  labelAction?: ReactNode;
}

const Select: React.FC<SelectProps> = ({
  color = 'info',
  inputSize = 'sm',
  className = '',
  label,
  required = false,
  labelClassName,
  hint,
  fullWidth = true,
  labelAction,
  ...props
}) => {
  const baseClass = 'select border-gray-400';
  const variantClass = `select-${color}`;
  const sizeClass = `select-${inputSize}`;

  return (
    <div className={`flex ${fullWidth ? 'w-full' : ''} flex-col gap-1`}>
      {label && (
        <InputLabel
          labelAction={labelAction}
          label={label}
          required={required}
          className={labelClassName}
        />
      )}

      <select
        className={twMerge(
          `${baseClass} ${variantClass} ${sizeClass} h-max w-full min-w-max py-0 focus:border-2 focus:outline-0`,
          className,
        )}
        {...props}
        required={required}
      />
      {hint && <p className={'text-right text-xs'}>{hint}</p>}
    </div>
  );
};

export default Select;
