import { omit } from 'lodash-es';
import Papa from 'papaparse';

/**
 * Downloads a CSV file generated from the provided data array.
 *
 * @param {Record<any, any>[]} array - The array of objects to be converted into CSV format.
 * @param {string} filename - The name of the CSV file to be downloaded.
 * @param {string[]} [excludeKeys] - Optional list of keys to exclude from the CSV data.
 * @param {(array: any[], excludeKeys?: string[]) => any[]} [parseFunction] - Optional custom function to preprocess the `array` before generating CSV.
 * @param {string[]} [order=[]] - Optional array specifying the order of keys in the output CSV data. Keys not included will appear afterward.
 */
export const downloadCSV = (
  array: Record<any, any>[],
  filename: string,
  excludeKeys?: string[],
  parseFunction?: (array: any[], excludeKeys?: string[]) => any[],
  order: string[] = [],
) => {
  const link = document.createElement('a');

  // Use parseFunction or map the array after omitting excludeKeys
  const processedData = parseFunction
    ? parseFunction(array, excludeKeys)
    : array.map((item) => (excludeKeys ? omit(item, excludeKeys) : item));

  // Adjust object keys to match the order
  const orderedData = processedData.map((item) => {
    const orderedItem: Record<string, any> = {};

    // Add keys in the specified order
    order.forEach((key) => {
      if (key in item) {
        orderedItem[key] = item[key];
      }
    });

    // Add remaining keys that were not in the `order` array
    Object.keys(item).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(orderedItem, key)) {
        orderedItem[key] = item[key];
      }
    });

    return orderedItem;
  });

  // Generate CSV using Papa Parse
  const csv = Papa.unparse(orderedData, {
    header: true,
  });

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();
};
