import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetPersonsQuery,
  useGetPersonsQuery,
} from '@/__generated__/graphql';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import BaseTable from '@/components/baseTable/BaseTable';
import Input from '@/components/basic/inputs/Input';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { PersonDetailsSideDrawer } from '@/pages/PersonsPage/personDetailSections/PersonDetailsSideDrawer';
import { PersonMembershipStatusEnum } from '@/shared/enums';
import { formatPhoneNumber, removeInvalidFilters } from '@/shared/utils/utils';

export const PersonsPage = () => {
  const [searchParams] = useSearchParams();
  //const [editPersonId, setEditPersonId] = useState('');
  //const hasRole = hasRoleAccess(user!.roles, [RolesEnum.customerSuccess]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPersonId, setSelectedPersonId] = useState(searchParams.get('p') ?? '');
  const debouncedSearch = useRef(
    debounce(async (criteria: string) => {
      setSearchTerm(criteria);
    }, 300),
  ).current;
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([{ id: 'created_at', desc: true }]);
  const [filters, setFilters] = useState<FilterInput[]>([]);
  const {
    data: getPersonsRes,
    loading,
    refetch,
  } = useGetPersonsQuery({
    variables: { filters, pagination: { limit: pagination.pageSize, offset: 0 }, sort: undefined },
  });
  const persons = getPersonsRes?.getPersons.persons;
  const totalRecords = getPersonsRes?.getPersons.totalRecords;
  const columns: ColumnDef<GetPersonsQuery['getPersons']['persons'][0]>[] = [
    { accessorKey: 'id', header: 'ID' },
    {
      header: 'Name',
      accessorFn: (record) => `${record?.firstName} ${record?.lastName}`,
      enableSorting: false,
    },
    {
      accessorKey: 'primaryEmail',
      header: 'Email',
      enableSorting: true,
    },
    { accessorKey: 'status', header: 'Status' },
    { accessorKey: 'membershipStatus', header: 'Membership Status' },
    {
      header: 'Membership Start Date',
      accessorFn: (record) =>
        record.membershipStartedAt ? dayjs(record.membershipStartedAt).format('MMM D, YYYY') : '',
      accessorKey: 'membershipStartedAt',
    },
    {
      accessorKey: 'city',
      header: 'City',
      enableSorting: true,
    },
    {
      accessorKey: 'phoneNumber',
      header: 'Phone Number',
      enableSorting: false,
      accessorFn: (record) => formatPhoneNumber(record.phoneNumber || ''),
    },
    {
      accessorKey: 'gender',
      header: 'Gender',
    },
    {
      accessorKey: 'dateOfBirth',
      header: 'Age',
      accessorFn: (record) =>
        record.dateOfBirth
          ? `${dayjs(record.dateOfBirth).format('MMM DD, YYYY')} (${dayjs(record.dateOfBirth).fromNow()})`
          : '',
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      accessorFn: (record) => dayjs(record.createdAt).format('MMM D, YYYY'),
      enableSorting: true,
    },
  ];

  // if (hasRole) {
  //   columns.unshift({
  //     id: 'action',
  //     accessorFn: (record) => record.id,
  //     cell: ({ cell }) => (
  //       <div className={'max-w-0'}>
  //         <Button
  //           color={'ghost'}
  //           size={'xs'}
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             setEditPersonId(cell.getValue() as string);
  //           }}
  //         >
  //           <PencilIcon className={'w-4'} />
  //         </Button>
  //       </div>
  //     ),
  //     enableSorting: false,
  //     header: 'Edit',
  //   });
  // }

  const getFilters = (): IFilter[] => {
    return [
      { field: 'first_name', type: 'string', operations: [FilterConditionOperatorEnum.Contains] },
      { field: 'last_name', type: 'string', operations: [FilterConditionOperatorEnum.Contains] },
      { field: 'status', type: 'string', operations: [FilterConditionOperatorEnum.Contains] },
      {
        field: 'membership_status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(PersonMembershipStatusEnum),
      },
      { field: 'city', type: 'string', operations: [FilterConditionOperatorEnum.Contains] },
      {
        field: 'gender',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Contains, FilterConditionOperatorEnum.Equals],
        values: ['Man', 'Woman', 'Transgender', 'Non-binary / non-conforming'],
      },
    ];
  };

  const refetchPersons = async () => {
    const tempFilters = removeInvalidFilters([
      ...filters,
      { field: 'search', operator: FilterConditionOperatorEnum.Contains, value: searchTerm },
    ]);

    refetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: tempFilters,
    });
  };

  const handleAccountDetailsClose = () => {
    setSelectedPersonId('');
  };

  useEffect(() => {
    refetchPersons();
  }, [filters, sorting, pagination, searchTerm]);

  return (
    <BasicLayout title={'All Persons'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <Input
          onChange={(event) => debouncedSearch(event.target.value)}
          className={'w-[300px] rounded-none'}
          placeholder={'Search name, phone, or email'}
          inputSize={'sm'}
          fullWidth={false}
        />
        <span className={'flex-1'} />
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
      </div>

      <BaseTable
        paginationDetails={pagination}
        sorting={sorting}
        data={persons || []}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        onRowClick={(record) => setSelectedPersonId(record.id)}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        totalRecords={totalRecords}
      />
      <PersonDetailsSideDrawer personId={selectedPersonId} onClose={handleAccountDetailsClose} />
      {/*<UpdatePersonModal*/}
      {/*  personId={editPersonId}*/}
      {/*  isOpen={!!editPersonId}*/}
      {/*  onClose={() => setEditPersonId('')}*/}
      {/*/>*/}
    </BasicLayout>
  );
};
