import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useGetStatsQuery } from '@/__generated__/graphql';
import Select from '@/components/basic/inputs/Select';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';
import { useAuth } from '@/hooks/useAuth';
import { InterviewStatsMemberships } from '@/pages/DashboardPage/SectionStats/Interviews/InterviewStatsMemberships';
import { InterviewStatsTable } from '@/pages/DashboardPage/SectionStats/Interviews/InterviewStatsTable';
import { RolesEnum } from '@/shared/enums';
import { hasRoleAccess, isRoleAdmin } from '@/shared/utils/rolesUtils';

export const InterviewStatsSection = () => {
  const { user } = useAuth();
  const hasInterviewerRole = hasRoleAccess(user!.roles, [RolesEnum.interviewer]);
  const isAdmin = isRoleAdmin(user?.roles || [], RolesEnum.interviewer);
  const { data, refetch, loading } = useGetStatsQuery({
    variables: { filters: { startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD') } },
    notifyOnNetworkStatusChange: true,
  });
  const stats = data?.getInterviewStats;
  const [timePeriod, setTimePeriod] = useState('rolling-30');
  const [filters, setFilters] = useState<{ startDate?: string; endDate?: string }>({});

  if (!hasInterviewerRole || !isAdmin) {
    return null;
  }

  useEffect(() => {
    if (!timePeriod) {
      setFilters({});
    } else if (timePeriod === 'currentMonth') {
      setFilters({
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      });
    } else if (timePeriod.startsWith('rolling-')) {
      const rollback = parseInt(timePeriod.split('-')[1]);
      setFilters({
        startDate: dayjs().subtract(rollback, 'days').format('YYYY-MM-DD'),
      });
    } else {
      const firstDayOfMonth = dayjs(timePeriod).startOf('month');
      const lastDayOfMonth = dayjs(timePeriod).endOf('month');
      setFilters({
        startDate: firstDayOfMonth.format('YYYY-MM-DD'),
        endDate: lastDayOfMonth.format('YYYY-MM-DD'),
      });
    }
  }, [timePeriod]);

  useEffect(() => {
    refetch({ filters });
  }, [filters, user, hasInterviewerRole]);

  return (
    <SectionWithHeader
      title={'Interview Leaderboard'}
      className={'w-max'}
      action={
        <>
          {loading && <div className={'loading bg-white'} />}
          <Select
            inputSize={'xs'}
            fullWidth={false}
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
          >
            <option value={'rolling-30'}>Rolling 30 Days</option>
            <option value={'rolling-60'}>Rolling 60 Days</option>
            <option value={'rolling-90'}>Rolling 90 Days</option>
            <option value={'currentMonth'}>Current Month</option>
            <option value={''}>All Time</option>
            {new Array(6).fill(true).map((_, i) => {
              const date = dayjs()
                .startOf('month')
                .subtract(i + 1, 'month');
              return (
                <option key={i} value={date.format('MMM YYYY')}>
                  {date.format('MMM YYYY')}
                </option>
              );
            })}
          </Select>
        </>
      }
    >
      <div className={'flex items-center gap-4'}>
        <InterviewStatsTable stats={stats || []} isAdmin={isAdmin} />
        <div className={'block h-[200px] w-[1px] bg-gray-400'} />
        <InterviewStatsMemberships stats={stats || []} isAdmin={isAdmin} userId={user!.id!} />
      </div>
    </SectionWithHeader>
  );
};
