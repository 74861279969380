import dayjs from 'dayjs';

import { GetExperienceQuery } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { ExperienceCategoryEnum } from '@/shared/enums';

type Props = {
  experience: GetExperienceQuery['getExperience'];
};

export const DetailsTabContent = ({ experience }: Props) => {
  const details = [
    {
      title: 'Title',
      value: experience.title,
    },
    {
      title: 'Date',
      value: experience.date
        ? dayjs(experience.date as Date).format('MMM DD, YYYY hh:mm a  z')
        : '',
    },
    {
      title: 'Category',
      value: experience.experienceCategory,
    },
    {
      title: 'Status',
      value: experience.status,
    },
    {
      title: 'Booking Status',
      value: experience.bookingStatus,
    },
    {
      title: 'Price/Seat',
      value: '$' + (experience.pricePerSeat / 100).toFixed(2),
    },
    {
      title: 'Cost/Seat',
      value: '$' + (experience.costPerSeat / 100).toFixed(2),
    },
    {
      title: 'Base Cost',
      value: '$' + ((experience.baseCost || 0) / 100).toFixed(2),
    },
    {
      title: 'Total Tickets',
      value: experience.maxParticipants,
    },
    {
      title: 'Available Tickets',
      value: experience.ticketsLeft,
    },
    {
      title: 'Bookings Count',
      value: experience.bookingsCount,
    },
    {
      title: 'Guests Per Member',
      value: experience.maxGuestsPerMember,
    },
    {
      title: 'Booking Cutoff',
      value: experience.lastBookingAt
        ? dayjs(experience.lastBookingAt as Date).format('MMM DD, YYYY hh:mm a  z')
        : '',
    },
    {
      title: 'Waitlist Activated',
      value: experience.waitlistActive ? 'Yes' : 'No',
    },
    {
      title: 'Attendees Visible',
      value: experience.attendeesVisible ? 'Yes' : 'No',
    },
    {
      hide: experience.experienceCategory !== ExperienceCategoryEnum.circle,
      title: 'Topic',
      value: experience?.topic?.category,
    },
    {
      title: 'Short Description',
      value: experience.shortDescription,
    },
    {
      title: 'Description',
      value: experience.description,
    },
    {
      title: 'Arrival Instructions',
      value: experience.arrivalInstructions,
    },
  ];

  const locationDetails = [
    {
      title: 'Name',
      value: experience?.location?.name,
    },
    {
      title: 'City',
      value: experience?.location?.cityRef?.name,
    },
    {
      title: 'Neighborhood',
      value: experience?.location?.neighborhoodRef?.name,
    },
    {
      title: 'Address',
      value: experience?.location?.streetAddress,
    },
    {
      title: 'Parking Details',
      value: experience?.location?.parkingDetails,
    },
  ];

  return (
    <div className={'flex flex-col gap-4 p-4'}>
      <DetailTableSection title={'Details'} details={details} />
      <DetailTableSection title={'Location'} details={locationDetails} />
    </div>
  );
};
