import { useCombobox } from 'downshift';
import { debounce } from 'lodash-es';
import React, { InputHTMLAttributes, ReactNode, useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { InputLabel } from '@/components/basic/inputs/InputLabel';

// Autocomplete interface
interface AutocompleteProps<T> extends InputHTMLAttributes<HTMLInputElement> {
  color?: 'primary' | 'secondary' | 'accent' | 'info' | 'neutral';
  inputSize?: 'xs' | 'sm' | 'md' | 'lg';
  label?: string;
  labelClassName?: HTMLLabelElement['className'];
  hint?: string;
  isErrorHint?: boolean;
  fullWidth?: boolean;
  items: T[];
  renderItem: (item: T) => ReactNode;
  itemToString: (item: T | null) => string;
  itemIdentifierKey: keyof T;
  onInputValueChange?: (arg: string) => void;
  onSelectedItemChange?: (arg: T | null) => void;
  initialInputValue?: string;
  loading?: boolean; // Added the loading prop
}

const Autocomplete = <T,>({
  color = 'info',
  inputSize = 'sm',
  label,
  required = false,
  labelClassName,
  hint,
  fullWidth = true,
  items,
  renderItem,
  itemIdentifierKey,
  itemToString,
  onInputValueChange,
  onSelectedItemChange,
  isErrorHint,
  initialInputValue,
  loading = false, // Default loading to false
  ...props
}: AutocompleteProps<T>) => {
  const baseClass = 'input border-gray-400';
  const variantClass = `input-${color}`;
  const sizeClass = `input-${inputSize}`;
  const debouncedSearch = useMemo(
    () => (onInputValueChange ? debounce(onInputValueChange, 500) : undefined),
    [onInputValueChange],
  );

  const {
    isOpen,
    highlightedIndex,
    getMenuProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    inputValue,
    selectedItem,
  } = useCombobox({
    items,
    itemToString,
    initialInputValue,
  });

  useEffect(() => {
    if (onInputValueChange && debouncedSearch) {
      debouncedSearch(inputValue);
    }
  }, [inputValue, debouncedSearch, onInputValueChange]);

  useEffect(() => {
    if (onSelectedItemChange) {
      onSelectedItemChange(selectedItem);
    }
  }, [selectedItem, onSelectedItemChange]);

  return (
    <div className={`flex ${fullWidth ? 'w-full' : 'w-max'} relative flex-col gap-1`}>
      {label && (
        <InputLabel
          label={label}
          required={required}
          className={labelClassName}
          {...getLabelProps()}
        />
      )}

      {loading ? (
        // Placeholder loading view
        <div className="loading loading-lg">Loading...</div>
      ) : (
        <>
          <input
            className={twMerge(
              `${baseClass} ${variantClass} ${sizeClass} w-full focus:border-2 focus:outline-0`,
              props.className,
            )}
            {...getInputProps({ required })}
          />

          <ul
            {...getMenuProps()}
            className={`absolute top-14 z-10 max-h-80 w-full overflow-scroll bg-white p-0 shadow-md ${
              !(isOpen && items.length) && 'hidden'
            }`}
          >
            {isOpen &&
              items.map((item, index) => (
                <li
                  className={'border-b'}
                  key={(item[itemIdentifierKey] as string | number) || index}
                  style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
                  {...getItemProps({ item, index })}
                >
                  {renderItem(item)}
                </li>
              ))}
          </ul>
        </>
      )}

      {hint && <p className={`text-right text-xs ${isErrorHint && 'text-error'}`}>{hint}</p>}
    </div>
  );
};

export default Autocomplete;
