import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  Experience,
  FilterConditionOperatorEnum,
  GetExperienceQuery,
  useDeleteExperienceMutation,
  useGetExperienceLazyQuery,
  useGetLocationsLazyQuery,
  useGetTopicsLazyQuery,
  useUpsertExperienceMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Autocomplete from '@/components/basic/inputs/Autocomplete';
import Checkbox from '@/components/basic/inputs/Checkbox';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import Textarea from '@/components/basic/inputs/Textarea';
import { ModalBase } from '@/components/modals/ModalBase';
import { ExperienceCategoryEnum, ExperienceStatusEnum, LocationStatusEnum } from '@/shared/enums';
import { toTitleCase } from '@/shared/utils/utils';

type Props = {
  experienceId?: string;
  isOpen: boolean;
  onClose: () => void;
};

type UpsertFormType = Partial<GetExperienceQuery['getExperience']> & { topicId?: string };

const defaultForm: UpsertFormType = {
  title: '',
  status: ExperienceStatusEnum.draft,
  locationId: '',
  maxParticipants: 0,
  maxGuestsPerMember: 0,
  description: '',
  shortDescription: '',
  date: '',
  publishAt: '',
  lastBookingAt: '',
  baseCost: undefined,
  costPerSeat: undefined,
  pricePerSeat: undefined,
  experienceCategory: '',
  waitlistActive: true,
  notes: '',
  arrivalInstructions: '',
  topicId: '',
  attendeesVisible: true,
};

export const UpsertExperienceModal = ({ experienceId, isOpen, onClose }: Props) => {
  const [locationSearch, setLocationSearch] = useState('');
  const [topicSearch, setTopicSearch] = useState('');
  const [upsertExperience, { loading: upsertLoading }] = useUpsertExperienceMutation();
  const [deleteExperience, { loading: deleteLoading }] = useDeleteExperienceMutation();
  const [getLocations, { data: locationsRes }] = useGetLocationsLazyQuery();
  const [getTopics, { data: topicsRes }] = useGetTopicsLazyQuery();
  const topics = topicsRes?.getTopics.topics || [];
  const locations = locationsRes?.getLocations.locations || [];
  const [getExperience, { loading: loadingExperience, data }] = useGetExperienceLazyQuery();
  const originalExperience = data?.getExperience;
  const [form, setForm] = useState<UpsertFormType>({
    ...defaultForm,
  });

  const fetchExperience = async (id: string) => {
    const { data } = await getExperience({
      variables: { id },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

    setForm(data?.getExperience || { ...defaultForm });

    if (data?.getExperience?.location?.name) {
      setLocationSearch(data?.getExperience.location.name);
    }

    if (data?.getExperience?.topic?.category) {
      setTopicSearch(data?.getExperience.topic.category);
    }
  };

  const handleSubmitLogic = debounce(async (e) => {
    e.preventDefault();

    if (!form.title) {
      return;
    }

    try {
      const res = await upsertExperience({
        variables: {
          id: experienceId,
          input: {
            title: form.title,
            status: form.status || ExperienceStatusEnum.draft,
            locationId: form.locationId,
            maxParticipants: Number(form.maxParticipants),
            maxGuestsPerMember: Number(form.maxGuestsPerMember),
            description: form.description,
            shortDescription: form.shortDescription,
            date: form.date || undefined,
            publishAt: form.publishAt || undefined,
            lastBookingAt: form.lastBookingAt || undefined,
            baseCost: form.baseCost,
            costPerSeat: form.costPerSeat,
            pricePerSeat: form.pricePerSeat,
            experienceCategory: form.experienceCategory,
            waitlistActive: form.waitlistActive || false,
            notes: form.notes,
            arrivalInstructions: form.arrivalInstructions,
            topicId: form.topicId,
            attendeesVisible: form.attendeesVisible,
          },
        },
      });
      setForm(res.data?.upsertExperience as Experience);
      enqueueSnackbar('Experience saved', { variant: 'success' });
      onClose();
    } catch (e: any) {
      enqueueSnackbar(e?.message, { variant: 'error' });
    }
  }, 300);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmitLogic(e);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (['costPerSeat', 'pricePerSeat', 'baseCost'].includes(name)) {
      value = !value || isNaN(value) ? undefined : Math.floor(Number(value)) * 100;
    }

    setForm({ ...form, [name]: value });
  };

  const handleDateChange = (e: any) => {
    let newDate = dayjs(e.target.value); // create dayjs date from input
    const name = e.target.name;
    if (form[e.target.name]) {
      const oldDate = dayjs(form[e.target.name]); // create dayjs date from form
      newDate = newDate.hour(oldDate.hour()).minute(oldDate.minute());
    }

    const updates = {
      ...form,
      [e.target.name]: newDate.toISOString(),
    };

    // Automatically update booking cutoff when date changes.
    if (name === 'date' && newDate.format('MM DD YYYY') !== dayjs(form.date).format('MM DD YYYY')) {
      updates.lastBookingAt = dayjs(newDate).subtract(2, 'day').endOf('day');
    }

    // keep the time from old date, and date from new date
    setForm(updates);
  };

  const handleTimeChange = (e: any) => {
    const [hours, minutes] = e.target.value.split(':').map(Number);
    const updatedDate = form[e.target.name] ? dayjs(form[e.target.name]) : dayjs();
    setForm({ ...form, [e.target.name]: updatedDate.hour(hours).minute(minutes).toISOString() });
  };

  const handleDelete = async () => {
    if (!originalExperience?.id) {
      return;
    }

    try {
      await deleteExperience({
        variables: {
          id: originalExperience.id,
        },
      });
      enqueueSnackbar('Experience deleted', { variant: 'success' });
      onClose();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Failed to delete experience', { variant: 'success' });
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (experienceId) {
        fetchExperience(experienceId);
      }
    } else {
      setForm({ ...defaultForm });
      setTopicSearch('');
      setLocationSearch('');
    }
  }, [isOpen, experienceId]);

  useEffect(() => {
    getLocations({
      variables: {
        sort: {
          id: 'name',
          order: 'ASC',
        },
        pagination: {
          limit: 10,
          offset: 0,
        },
        filters: [
          {
            field: 'search',
            operator: FilterConditionOperatorEnum.Contains,
            value: locationSearch,
          },
          {
            field: 'status',
            operator: FilterConditionOperatorEnum.Equals,
            value: LocationStatusEnum.Active,
          },
        ],
      },
    });
  }, [locationSearch]);

  useEffect(() => {
    getTopics({
      variables: {
        sort: {
          id: 'category',
          order: 'ASC',
        },
        pagination: {
          limit: 100,
          offset: 0,
        },
        filters: [
          {
            field: 'search',
            operator: FilterConditionOperatorEnum.Contains,
            value: topicSearch,
          },
        ],
      },
    });
  }, [topicSearch]);

  useEffect(() => {
    /*
     * Update the arrival instructions if they are blank with the locations default instructions.
     * */
    const location = locations.find((l) => l.id === form.locationId);
    if (location && !form.arrivalInstructions) {
      setForm({ ...form, arrivalInstructions: location.arrivalInstructions });
    }
  }, [form.locationId]);

  return (
    <ModalBase
      size={'xl'}
      title={form?.id ? 'Update Experience' : 'New Experience'}
      isOpen={isOpen}
      onClose={onClose}
      overflowAuto={true}
    >
      {!loadingExperience && (
        <form onSubmit={handleSubmit} className={'flex flex-col gap-4'}>
          <Input
            required={true}
            inputSize={'sm'}
            label={'Title'}
            value={form.title}
            onChange={handleChange}
            name={'title'}
          />
          <div className={'grid grid-cols-2 gap-2'}>
            <Select
              inputSize={'sm'}
              label={'Status'}
              value={form.status}
              name={'status'}
              onChange={handleChange}
              required={true}
            >
              <option value={''} disabled={true}>
                Select Status
              </option>
              {Object.values(ExperienceStatusEnum).map((status) => {
                return (
                  <option key={status} value={status}>
                    {toTitleCase(status)}
                  </option>
                );
              })}
            </Select>

            <Select
              inputSize={'sm'}
              label={'Category'}
              value={form.experienceCategory || ''}
              name={'experienceCategory'}
              onChange={handleChange}
              required={true}
            >
              <option value={''} disabled={true}>
                Select Category
              </option>
              {[ExperienceCategoryEnum.circle, ExperienceCategoryEnum.explore].map((status) => {
                return (
                  <option key={status} value={status}>
                    {toTitleCase(status)}
                  </option>
                );
              })}
            </Select>
            <div className={'grid grid-cols-2 gap-2'}>
              <Input
                fullWidth={true}
                required={true}
                inputSize={'sm'}
                label={'Experience Date'}
                onChange={handleDateChange}
                value={form.date ? dayjs(form.date).format('YYYY-MM-DD') : ''}
                name={'date'}
                type={'date'}
              />

              <Input
                required={true}
                inputSize={'sm'}
                label={'Experience Time'}
                onChange={handleTimeChange}
                value={form.date ? dayjs(form.date).format('HH:mm') : ''}
                name={'date'}
                type={'time'}
                hint={`Local Time: ${dayjs().format('z') || 'UTC'}`}
              />
            </div>

            {!experienceId || (experienceId && form.id) ? (
              <Autocomplete
                label={'Location'}
                required={true}
                placeholder={'Select Location'}
                items={locations}
                name={'locationId'}
                renderItem={(location) => (
                  <div className={'p-1'}>
                    <h4 className={'text-md'}>{location.name}</h4>
                    <p className={'text-xs text-gray-600'}>{location.city}</p>
                  </div>
                )}
                itemToString={(location) => (location ? `${location.name}` : '')}
                itemIdentifierKey={'id'}
                onInputValueChange={(input) => {
                  setLocationSearch(input);
                }}
                onSelectedItemChange={(item) => {
                  if (item?.id === form.locationId) return;
                  return setForm({ ...form, locationId: item?.id });
                }}
                initialInputValue={form.location?.name || ''}
              />
            ) : null}

            {form.experienceCategory === ExperienceCategoryEnum.circle &&
            (!experienceId || form.id) ? (
              <Autocomplete
                label={'Topic'}
                required={true}
                placeholder={'Select Topic'}
                items={topics}
                renderItem={(topic) => (
                  <div className={'p-1'}>
                    <h4 className={'text-md'}>{topic.category}</h4>
                  </div>
                )}
                itemToString={(topic) => (topic ? `${topic.category}` : '')}
                itemIdentifierKey={'id'}
                onInputValueChange={(input) => setTopicSearch(input)}
                onSelectedItemChange={(item) => {
                  if (item?.id === form.topicId) return;
                  return setForm({ ...form, topicId: item?.id });
                }}
                initialInputValue={form.topic?.category || ''}
              />
            ) : null}

            <Input
              required={true}
              inputSize={'sm'}
              label={'Base Cost (USD)'}
              onChange={handleChange}
              value={isNaN(Number(form.baseCost)) ? '' : (form.baseCost || 0) / 100}
              name={'baseCost'}
              type={'number'}
              min={0}
            />
            <Input
              required={true}
              inputSize={'sm'}
              label={'Cost Per Seat (USD)'}
              onChange={handleChange}
              value={isNaN(Number(form.costPerSeat)) ? '' : (form.costPerSeat || 0) / 100}
              name={'costPerSeat'}
              type={'number'}
              min={0}
            />
            <Input
              required={true}
              inputSize={'sm'}
              label={'Price Per Seat (USD)'}
              onChange={handleChange}
              value={isNaN(Number(form.pricePerSeat)) ? '' : (form.pricePerSeat || 0) / 100}
              name={'pricePerSeat'}
              type={'number'}
              min={0}
            />
            <Input
              required={form.status !== ExperienceStatusEnum.draft}
              inputSize={'sm'}
              label={'Max. Participants'}
              onChange={handleChange}
              value={form.maxParticipants}
              name={'maxParticipants'}
              type={'number'}
              min={0}
            />
            <Input
              required={form.status !== ExperienceStatusEnum.draft}
              inputSize={'sm'}
              label={'Max. Guests/Participant'}
              onChange={handleChange}
              value={form.maxGuestsPerMember}
              name={'maxGuestsPerMember'}
              type={'number'}
              min={0}
            />
            <div className={'grid grid-cols-2 gap-2'}>
              <Input
                fullWidth={true}
                required={form.status !== ExperienceStatusEnum.draft}
                inputSize={'sm'}
                label={'Publish Date'}
                onChange={handleDateChange}
                value={form.publishAt ? dayjs(form.publishAt).format('YYYY-MM-DD') : ''}
                name={'publishAt'}
                type={'date'}
              />
              <Input
                required={form.status !== ExperienceStatusEnum.draft}
                inputSize={'sm'}
                label={'Publish Time'}
                onChange={handleTimeChange}
                value={form.publishAt ? dayjs(form.publishAt).format('HH:mm') : ''}
                name={'publishAt'}
                type={'time'}
                hint={`Local Time: ${dayjs().format('z') || 'UTC'}`}
              />
            </div>
            <div className={'grid grid-cols-2 gap-2'}>
              <Input
                fullWidth={true}
                required={form.status !== ExperienceStatusEnum.draft}
                inputSize={'sm'}
                label={'Bookings Cutoff Date'}
                onChange={handleDateChange}
                value={form.lastBookingAt ? dayjs(form.lastBookingAt).format('YYYY-MM-DD') : ''}
                name={'lastBookingAt'}
                type={'date'}
              />
              <Input
                required={form.status !== ExperienceStatusEnum.draft}
                inputSize={'sm'}
                label={'Cutoff Time'}
                onChange={handleTimeChange}
                value={form.lastBookingAt ? dayjs(form.lastBookingAt).format('HH:mm') : ''}
                name={'lastBookingAt'}
                type={'time'}
                hint={`Local Time: ${dayjs().format('z') || 'UTC'}`}
              />
            </div>
            <div className={'flex gap-2'}>
              <Checkbox
                className={'ml-4 border-black'}
                label={'Activate Waitlist?'}
                checked={form.waitlistActive}
                onChange={handleChange}
                name={'waitlistActive'}
                fullWidth={false}
              />
              <Checkbox
                className={'ml-4 border-black'}
                label={'Attendees Visible?'}
                checked={form.attendeesVisible || false}
                onChange={handleChange}
                name={'attendeesVisible'}
                fullWidth={false}
              />
            </div>
          </div>
          <Textarea
            required={form.status !== ExperienceStatusEnum.draft}
            label={'Short Description'}
            value={form.shortDescription || ''}
            name={'shortDescription'}
            onChange={handleChange}
            className={'h-auto min-h-24 min-w-full max-w-full'}
            autoResize={true}
            maxLength={150}
            hint={`${form.shortDescription?.length || 0}/150`}
          />
          <Textarea
            required={form.status !== ExperienceStatusEnum.draft}
            label={'Long Description'}
            value={form.description || ''}
            name={'description'}
            onChange={handleChange}
            className={'min-h-24'}
            autoResize={true}
            maxLength={550}
            hint={`${form.description?.length || 0}/550`}
          />
          <Textarea
            required={form.status !== ExperienceStatusEnum.draft}
            label={'Arrival Instructions'}
            value={form.arrivalInstructions || ''}
            name={'arrivalInstructions'}
            onChange={handleChange}
            className={'min-h-24'}
            autoResize={true}
          />
          <Textarea
            label={'Notes'}
            value={form.notes || ''}
            name={'notes'}
            onChange={handleChange}
            className={'min-h-24'}
            autoResize={true}
          />
          <div className={'flex gap-2'}>
            {originalExperience && originalExperience.status != ExperienceStatusEnum.published && (
              <Button
                onClick={handleDelete}
                disabled={upsertLoading || deleteLoading}
                size={'xs'}
                color={'error'}
                type={'button'}
              >
                Delete
              </Button>
            )}
            <span className={'flex-1'} />
            <Button
              loading={upsertLoading}
              disabled={deleteLoading}
              size={'xs'}
              color={'success'}
              type={'submit'}
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </ModalBase>
  );
};
