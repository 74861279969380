import { GetPersonQuery, Person } from '@/__generated__/graphql';
import { CancelAccountTrigger } from '@/pages/PersonsPage/personDetailSections/components/CancelAccountTrigger';
import { PauseAccountTrigger } from '@/pages/PersonsPage/personDetailSections/components/PauseAccountTrigger';
import { ReactivateAccountTrigger } from '@/pages/PersonsPage/personDetailSections/components/ReactivateAccountTrigger';
import { PersonMembershipStatusEnum } from '@/shared/enums';

type Props = {
  person: GetPersonQuery['getPerson'];
};

export const PersonActions = ({ person }: Props) => {
  return (
    <div className={'mb-5 flex gap-2'}>
      {!person.membershipStatus ||
      person.membershipStatus === PersonMembershipStatusEnum.Canceled ||
      person?.subscription?.cancelAtPeriodEnd ? null : (
        <>
          <PauseAccountTrigger
            matchAfterDate={person?.matchAfterDate}
            personId={person.id}
            membershipStatus={person.membershipStatus ?? ''}
          />

          <CancelAccountTrigger
            person={person as Person}
            membershipStatus={person.membershipStatus ?? ''}
          />
        </>
      )}

      {person.membershipStatus === PersonMembershipStatusEnum.Canceled ||
      person.subscription?.cancelAtPeriodEnd ? (
        <ReactivateAccountTrigger person={person as Person} />
      ) : null}
    </div>
  );
};
