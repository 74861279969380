import { Role } from '@/__generated__/graphql';
import { RolesEnum } from '@/shared/enums';

/**
 * Returns a list of accessible roles for a user based on their current roles.
 *
 * @param {Role[]} allRoles - The complete list of possible roles in the system.
 * @param {Role[]} userRoles - The list of roles assigned to the user.
 * @return {Role[]} A list of roles that the user has access to.
 */
export function getAccessibleRoles(allRoles: Role[], userRoles: Role[]): Role[] {
  // If the user has 'owner' role, return all roles
  if (userRoles.some((role) => role.name === RolesEnum.owner)) {
    return allRoles;
  }

  // If the user has 'admin' role, return all roles except owner
  if (userRoles.some((role) => role.name === RolesEnum.admin)) {
    return allRoles.filter((role) => role.name !== RolesEnum.owner);
  }

  // If the user has no '-admin' role, return empty roles
  if (!userRoles.some((role) => role.name.endsWith('-admin'))) {
    return [];
  }

  // Set to store accessible role names for uniqueness
  const accessibleRoles = new Set<Role>();

  // Process each user role
  userRoles.forEach((userRole) => {
    // If user role has '-admin' suffix
    if (userRole.name.endsWith('-admin')) {
      const baseRole = userRole.name.split('-')[0];

      // Find all roles with the same base
      allRoles.forEach((role) => {
        if (role.name.startsWith(baseRole)) {
          accessibleRoles.add(role);
        }
      });
    }
  });

  return Array.from(accessibleRoles);
}

/**
 *
 */
export const isRoleAdmin = (userRoles: Role[], baseRole: string) => {
  const userRolesSet = new Set(userRoles.map((role) => role.name));
  return (
    userRolesSet.has(`admin`) || userRolesSet.has(`owner`) || userRolesSet.has(`${baseRole}-admin`)
  );
};

/**
 * Determines if a user has access based on their roles and the required roles for a resource.
 *
 * @param {Role[]} userRoles - The list of roles associated with the user.
 * @param {RolesEnum[]} requiredRoles - The required roles to access a resource.
 * @returns {boolean} Returns true if the user has any of the required roles, including related admin roles; otherwise, false.
 */
export const hasRoleAccess = (userRoles: Role[], requiredRoles: RolesEnum[]) => {
  if (!userRoles.length) {
    return false;
  }

  // Default roles with full access
  const accessRoles = new Set([RolesEnum.admin, RolesEnum.owner]);

  // Include required roles and their admin equivalents
  for (const role of requiredRoles) {
    accessRoles.add(role);
    if (!role.includes('-admin')) {
      accessRoles.add(`${role}-admin` as RolesEnum);
    }
  }

  // Check if userRoles match any of the accessRoles
  for (const role of userRoles) {
    if (accessRoles.has(role.name as RolesEnum)) {
      return true;
    }
  }

  return false;
};
