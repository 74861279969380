import { useEffect } from 'react';

import { Location, useGetCitiesQuery, useGetNeighborhoodsLazyQuery } from '@/__generated__/graphql';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import Textarea from '@/components/basic/inputs/Textarea';
import { LocationStatusEnum } from '@/shared/enums';

type Props = {
  location: Partial<Location>;
  setLocation: (location: Partial<Location>) => void;
};

export const UpsertLocationFormFields = ({ location, setLocation }: Props) => {
  const cityRes = useGetCitiesQuery();
  const cities = cityRes.data?.getCities || [];
  const [getNeighborhoods, { data }] = useGetNeighborhoodsLazyQuery();
  const neighborhoods = data?.getNeighborhoods || [];

  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setLocation({ ...location, [name]: value });
  };

  useEffect(() => {
    if (location.cityId) {
      getNeighborhoods({
        variables: {
          cityId: location.cityId,
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [location.cityId]);

  useEffect(() => {
    if (location.neighborhoodId) {
      if (
        neighborhoods.length &&
        !neighborhoods.some((neighborhood) => neighborhood.id === location.neighborhoodId)
      ) {
        setLocation({ ...location, neighborhoodId: '' });
      }
    }
  }, [neighborhoods]);

  return (
    <>
      <div className={'grid w-full grid-cols-2 gap-2'}>
        <Input
          required={true}
          label={'Name'}
          value={location.name!}
          name={'name'}
          onChange={handleFormChange}
        />
        <Select
          label={'Status'}
          required={true}
          name={'status'}
          value={location.status || ''}
          onChange={handleFormChange}
        >
          <option value={''} disabled={true}>
            Select Status
          </option>
          {Object.values(LocationStatusEnum).map((status) => (
            <option value={status} key={status}>
              {status}
            </option>
          ))}
        </Select>
        <Select
          label={'City'}
          required={true}
          name={'cityId'}
          value={location.cityId || ''}
          onChange={handleFormChange}
        >
          <option value={''} disabled={true}>
            Select City
          </option>
          {cities.map((city) => (
            <option value={city.id} key={city.id}>
              {city.name}
            </option>
          ))}
        </Select>

        <Select
          required={true}
          disabled={!location.cityId}
          label={'Neighborhood'}
          value={location.neighborhoodId || ''}
          name={'neighborhoodId'}
          onChange={handleFormChange}
        >
          <option value={''} disabled={true}>
            Select Neighborhood
          </option>
          {neighborhoods.map((neighborhood) => (
            <option value={neighborhood.id} key={neighborhood.id}>
              {neighborhood.name}
            </option>
          ))}
        </Select>
        <Input
          required={location.status === LocationStatusEnum.Active}
          label={'Phone Number'}
          value={location.phoneNumber!}
          name={'phoneNumber'}
          onChange={handleFormChange}
          type={'tel'}
        />
        <Input
          required={location.status === LocationStatusEnum.Active}
          label={'Contact Name'}
          value={location.contactName!}
          name={'contactName'}
          onChange={handleFormChange}
        />
        <Input
          required={location.status === LocationStatusEnum.Active}
          label={'Contact Email'}
          value={location.contactEmail!}
          name={'contactEmail'}
          onChange={handleFormChange}
          type={'email'}
        />

        <Input
          required={location.status === LocationStatusEnum.Active}
          label={'Contact Phone Number'}
          value={location.contactPhoneNumber!}
          name={'contactPhoneNumber'}
          onChange={handleFormChange}
          type={'tel'}
        />

        <Input
          required={location.status === LocationStatusEnum.Active}
          label={'Max. Capacity'}
          value={location.maxCapacity!}
          name={'maxCapacity'}
          onChange={handleFormChange}
          type={'number'}
        />
        <Input
          required={location.status === LocationStatusEnum.Active}
          label={'Min. Capacity'}
          value={location.minCapacity!}
          name={'minCapacity'}
          onChange={handleFormChange}
          type={'number'}
        />
      </div>
      <div className={'grid w-full grid-cols-1 gap-2'}>
        <Input
          required={location.status === LocationStatusEnum.Active}
          label={'Street Address'}
          value={location.streetAddress || ''}
          name={'streetAddress'}
          onChange={handleFormChange}
        />
        <Textarea
          required={location.status === LocationStatusEnum.Active}
          label={'Parking Details'}
          value={location.parkingDetails || ''}
          name={'parkingDetails'}
          onChange={handleFormChange}
          className={'min-h-24'}
          autoResize={true}
        />
        <Textarea
          required={location.status === LocationStatusEnum.Active}
          label={'Arrival Instructions'}
          value={location.arrivalInstructions || ''}
          name={'arrivalInstructions'}
          onChange={handleFormChange}
          className={'min-h-24'}
          autoResize={true}
        />
        <Textarea
          label={'Notes'}
          value={location.notes || ''}
          name={'notes'}
          onChange={handleFormChange}
          className={'min-h-24'}
          autoResize={true}
        />
      </div>
    </>
  );
};
