import { ArrowUpRightIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { GetPersonExperiencesQuery, useGetPersonExperiencesQuery } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { ButtonWithDropdown } from '@/components/basic/buttons/ButtonWithDropdown';
import { RefundPersonExperienceModal } from '@/components/modals/modals/RefundPersonExperienceModal';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';
import { SimpleTable, SimpleTableColumn } from '@/components/tables/SimpleTable';
import { getStripeUrl } from '@/shared/utils/utils';

type Props = {
  personId: string;
};

export const PersonExperiences = ({ personId }: Props) => {
  const { data, loading, refetch } = useGetPersonExperiencesQuery({ variables: { personId } });
  const experiences = data?.getPersonExperiences;
  const [selectedExperienceToRefund, setSelectedExperienceToRefund] = useState('');
  const experienceColumns: SimpleTableColumn<
    GetPersonExperiencesQuery['getPersonExperiences'][0]
  >[] = [
    {
      accessorKey: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      accessorKey: 'name',
      headerName: 'Name',
      accessorFunc: (row) => {
        return (
          <Link target={'_blank'} className={'link'} to={`/experiences?e=${row.experience?.id}`}>
            {row.experience?.title}
          </Link>
        );
      },
      className: 'whitespace-nowrap w-max text-ellipsis',
      enableTooltip: true,
    },
    {
      accessorKey: 'locationName',
      headerName: 'Location Name',
      accessorFunc: (row) => {
        return row.location?.name;
      },
      enableTooltip: true,
    },
    {
      accessorKey: 'locationCity',
      headerName: 'Location City',
      accessorFunc: (row) => {
        return row.location?.city;
      },
    },
    {
      accessorKey: 'ticket_count',
      headerName: '# Tickets',
    },
    {
      accessorKey: 'date',
      headerName: 'Date',
      accessorFunc: (row) => {
        return row?.experience?.date ? dayjs(row.experience.date).format('MMM D, YYYY') : '';
      },
      className: 'whitespace-nowrap',
    },
    {
      headerName: 'Payment Status',
      accessorKey: 'stripePaymentStatus',
      accessorFunc: (record) => {
        return (
          <div className={'flex'}>
            <span className={'block'}>{record.transaction.status}</span>
            <Button
              onClick={() =>
                window.open(
                  `${getStripeUrl()}/payments/${record.transaction.stripePaymentIntentId}`,
                )
              }
              color={'ghost'}
              size={'xs'}
              variant={'link'}
            >
              <ArrowUpRightIcon color={'black'} className={'h-4 w-4'} />
            </Button>
          </div>
        );
      },
    },
    {
      accessorKey: 'feedbackRating',
      headerName: 'Feedback Rating',
      accessorFunc: (row) => {
        return (
          <span className={'flex items-center gap-1'}>
            {row.feedback?.overallRating ? `${row.feedback?.overallRating}/5` : null}
            {row?.feedback?.feedback ? (
              <InformationCircleIcon title={row.feedback.feedback} className={'h-4 w-4'} />
            ) : null}
          </span>
        );
      },
    },
    {
      headerName: '',
      accessorKey: 'actions',
      accessorFunc: (row) => {
        return (
          <>
            {row.transaction.status === 'succeeded' && (
              <ButtonWithDropdown
                dropdownActions={[
                  {
                    label: 'Remove/Refund',
                    onClick: () => setSelectedExperienceToRefund(row.experienceId),
                  },
                ]}
                size={'xs'}
              >
                Actions
              </ButtonWithDropdown>
            )}
          </>
        );
      },
    },
  ];

  const handleRefundCloseModal = () => {
    setSelectedExperienceToRefund('');
    refetch({ personId });
  };

  return (
    <>
      <SectionWithHeader title={'Experiences'} className={'overflow-visible'}>
        <SimpleTable
          className={'min-h-52'}
          data={[...(experiences || [])]?.sort((a, b) => {
            const dateA: any = new Date(a?.experience?.date || -1);
            const dateB: any = new Date(b?.experience?.date || -1);
            return dateB - dateA;
          })}
          columns={experienceColumns}
          loading={loading}
        />
      </SectionWithHeader>

      {experiences?.length ? (
        <RefundPersonExperienceModal
          personId={personId}
          experienceId={selectedExperienceToRefund}
          isOpen={Boolean(selectedExperienceToRefund)}
          onClose={handleRefundCloseModal}
        />
      ) : null}
    </>
  );
};
