import { FilterInput, useGetInterviewsQuery } from '@/__generated__/graphql';

export const useInterviews = (filters?: FilterInput[]) => {
  const {
    loading: isLoading,
    data: interviews,
    error,
    refetch,
  } = useGetInterviewsQuery({
    variables: { filters: filters || [] },
    fetchPolicy: 'cache-and-network',
  });

  return {
    isLoading,
    interviews: interviews?.getInterviews?.interviews || [],
    error,
    refetchInterviews: refetch,
  };
};
