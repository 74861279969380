import { captureException } from '@sentry/react';
import { debounce } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import {
  Location,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetLocationLazyQuery,
  useUpdateLocationMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { ModalBase } from '@/components/modals/ModalBase';
import { UpsertLocationFormFields } from '@/pages/Places/LocationsPage/components/UpsertLocationModal/UpsertLocationFormFields';
import { UpsertLocationImage } from '@/pages/Places/LocationsPage/components/UpsertLocationModal/UpsertLocationImage';

type Props = {
  locationId?: string;
  open: boolean;
  onClose: () => void;
};

const defaultLocation: Partial<Location> = {
  id: '',
  status: '',
  name: '',
  phoneNumber: '',
  streetAddress: '',
  contactEmail: '',
  contactName: '',
  contactPhoneNumber: '',
  neighborhoodId: '',
  cityId: '',
  arrivalInstructions: '',
  parkingDetails: '',
  notes: '',
  minCapacity: 0,
  maxCapacity: 0,
};

export const UpsertLocationModal = ({ locationId, open, onClose }: Props) => {
  const [location, setLocation] = useState<Partial<Location>>({ ...defaultLocation });
  const [getLocation] = useGetLocationLazyQuery();
  const [createLocation, { loading: createLoading }] = useCreateLocationMutation();
  const [updateLocation, { loading: updateLoading }] = useUpdateLocationMutation();
  const [deleteLocation, { loading: deleteLoading }] = useDeleteLocationMutation();

  const loading = createLoading || updateLoading || deleteLoading;

  const fetchLocation = async (locationId: string) => {
    const locationRes = await getLocation({
      variables: { id: locationId },
    });

    setLocation({ ...defaultLocation, ...(locationRes.data?.getLocation || {}) } as Location);
  };

  const handleDeleteLocation = async (locationId: string) => {
    try {
      await deleteLocation({
        variables: { id: locationId },
      });
      setLocation({ ...defaultLocation });
      enqueueSnackbar('Location deleted', { variant: 'success' });
      onClose();
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Failed to delete location', { variant: 'error' });
    }
  };

  const handleSubmitLogic = useCallback(
    debounce(async () => {
      try {
        const payload = {
          status: location.status!,
          name: location.name!,
          phoneNumber: location.phoneNumber || '',
          streetAddress: location.streetAddress || '',
          contactEmail: location.contactEmail || '',
          contactName: location.contactName || '',
          contactPhoneNumber: location.contactPhoneNumber || '',
          parkingDetails: location.parkingDetails || '',
          arrivalInstructions: location.arrivalInstructions || '',
          neighborhoodId: location.neighborhoodId || '',
          cityId: location.cityId || '',
          notes: location.notes || '',
          minCapacity:
            !location?.minCapacity || isNaN(location.minCapacity)
              ? undefined
              : parseInt(String(location.minCapacity)),
          maxCapacity:
            !location?.maxCapacity || isNaN(location.maxCapacity)
              ? undefined
              : parseInt(String(location.maxCapacity)),
        };

        if (location.id) {
          const updateRes = await updateLocation({
            variables: {
              id: location.id,
              input: payload,
            },
          });
          if (updateRes.data?.updateLocation) {
            setLocation(updateRes.data.updateLocation as Location);
          }
        } else {
          const createRes = await createLocation({
            variables: {
              input: payload,
            },
          });
          setLocation(createRes.data!.createLocation as Location);
        }
        enqueueSnackbar(location.id ? 'Updated Location' : 'Created Location', {
          variant: 'success',
        });
        onClose();
      } catch (e) {
        captureException(e);
        enqueueSnackbar(location.id ? 'Failed to updated Location' : 'Failed to created Location', {
          variant: 'error',
        });
      }
    }, 300),
    [location],
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmitLogic();
  };

  useEffect(() => {
    if (locationId) {
      fetchLocation(locationId);
    }

    return () => {
      setLocation({ ...defaultLocation });
    };
  }, [locationId, open]);

  return (
    <ModalBase
      title={(location?.id ? 'Update' : 'Create') + ' Location'}
      isOpen={open}
      onClose={onClose}
      overflowAuto={true}
      size={'lg'}
    >
      <form className={'flex flex-col gap-2 overflow-auto'} onSubmit={handleSubmit}>
        <UpsertLocationFormFields location={location} setLocation={setLocation} />

        <div className={'flex gap-2'}>
          {location.id && !location.experiencesCount ? (
            <Button
              size={'xs'}
              color={'error'}
              type={'button'}
              disabled={loading}
              loading={deleteLoading}
              onClick={() => handleDeleteLocation(location.id!)}
            >
              Delete
            </Button>
          ) : null}
          <span className={'flex-1'} />
          <Button
            size={'xs'}
            color={'success'}
            type={'submit'}
            loading={createLoading || updateLoading}
            disabled={loading}
          >
            Save
          </Button>
        </div>

        <UpsertLocationImage location={location} />
      </form>
    </ModalBase>
  );
};
