import { captureException } from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  Location,
  useGetSignedImageUploadUrlLazyQuery,
  usePatchLocationMutation,
} from '@/__generated__/graphql';
import { FileUploadButton } from '@/components/basic/buttons/FileUploadButton';
import { LOCATIONS_IMAGE_BUCKET } from '@/shared/constants';
import { getSupabaseImageUrl, uploadFile } from '@/shared/utils/supabaseImagesUtils';

type Props = {
  location: Partial<Location>;
};

export const UpsertLocationImage = ({ location }: Props) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const [patchLocation] = usePatchLocationMutation();
  const [getSignedUploadUrl] = useGetSignedImageUploadUrlLazyQuery({ fetchPolicy: 'network-only' });
  const [imageUrl, setImageUrl] = useState('');

  const handleImageUpload = async (file?: File) => {
    if (!file || !location.id) {
      return;
    }

    try {
      setImageUrl('');
      setLoadingImage(true);

      // Extract the file extension from the uploaded file
      const fileExtension = file.name.split('.').pop();

      if (!fileExtension) {
        throw new Error('Failed to determine file extension');
      }

      // Include the file extension in the path
      const res = await getSignedUploadUrl({
        variables: { path: `${location.id}.${fileExtension}` },
      });
      const signedRes = res?.data?.getSignedImageUploadUrl;

      if (!signedRes?.signedUrl || !signedRes?.token) {
        throw new Error('Failed to get signed url');
      }

      await uploadFile(signedRes.signedUrl, signedRes.token, file);

      await patchLocation({
        variables: { id: location.id, input: { imagePath: signedRes.path } },
      });

      const data = await getSupabaseImageUrl(LOCATIONS_IMAGE_BUCKET, signedRes.path);
      setImageUrl(data || '');

      enqueueSnackbar('Image uploaded', { variant: 'success' });
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Failed to upload image', { variant: 'error' });
    } finally {
      setLoadingImage(false);
    }
  };

  useEffect(() => {
    if (location.imagePath) {
      (async () => {
        const data = await getSupabaseImageUrl(
          LOCATIONS_IMAGE_BUCKET,
          location?.imagePath as string,
        );
        setImageUrl(data || '');
      })();
    }

    return () => {
      setImageUrl('');
    };
  }, [location.imagePath]);

  return (
    <div className={'flex gap-4 border-t pt-4'}>
      <FileUploadButton
        disabled={!location.id}
        onFileUpload={handleImageUpload}
        buttonText={location.imagePath ? 'Replace Image' : 'Add Image'}
        size={'sm'}
        maxFileSizeMb={49}
        loading={loadingImage}
      />
      {imageUrl && <img src={imageUrl} alt={'Location Image'} className={'max-w-[200px]'} />}
    </div>
  );
};
