import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetTopicsQuery,
  useGetTopicsQuery,
} from '@/__generated__/graphql';
import BaseTable from '@/components/baseTable/BaseTable';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { UpsertTopicModal } from '@/pages/Experiences/TopicsPage/components/UpsertTopicModal';
import { removeInvalidFilters } from '@/shared/utils/utils';

export const TopicsPage = () => {
  const [pagination, setPagination] = useState({ pageSize: 100, pageIndex: 0 });
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'category', desc: false }]);
  const [filters] = useState<FilterInput[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: topicsRes,
    loading,
    refetch: topicsRefetch,
  } = useGetTopicsQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const topics = topicsRes?.getTopics?.topics || [];
  const totalRecords = topicsRes?.getTopics.totalRecords;

  const columns: ColumnDef<GetTopicsQuery['getTopics']['topics'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'category',
      header: 'Category',
    },
    {
      accessorKey: 'experiencesCount',
      header: 'Experiences',
      enableSorting: false,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      accessorFn: (record) => dayjs(record.createdAt).format('MMM D, YYYY h:mm A z'),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      accessorFn: (record) => dayjs(record.updatedAt).format('MMM D, YYYY h:mm A z'),
    },
  ];

  const handleModalClose = () => {
    setSelectedTopicId('');
    setShowTopicModal(false);
    refetchCurrentTopics();
  };

  const refetchCurrentTopics = async () => {
    const tempFilters = removeInvalidFilters([
      ...filters,
      { field: 'search', operator: FilterConditionOperatorEnum.Contains, value: searchTerm },
    ]);

    topicsRefetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: tempFilters,
    });
  };

  useEffect(() => {
    refetchCurrentTopics();
  }, [filters, sorting, pagination, searchTerm]);

  return (
    <BasicLayout title={'Circle Topics'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <Input
          fullWidth={true}
          className={'max-w-[300px]'}
          placeholder={'Search Categories'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span className={'flex-1'} />
        <Button size={'xs'} color={'success'} onClick={() => setShowTopicModal(true)}>
          New Topic
        </Button>
      </div>

      <BaseTable
        data={topics}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
        onRowClick={(record) => {
          setShowTopicModal(true);
          setSelectedTopicId(record.id);
        }}
      />
      <UpsertTopicModal
        open={showTopicModal}
        topicId={selectedTopicId}
        onClose={handleModalClose}
      />
    </BasicLayout>
  );
};
