/**
 * Converts a string to a color value.
 *
 * @param {string} str - The string to convert to color.
 * @param opacity
 * @return {string} The color value generated from the string.
 */
export function stringToColor(str: string = '', opacity: number = 1): string {
  if (!str) return '';
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }

  // Add opacity
  let alpha = Math.round(opacity * 255);
  alpha = alpha < 0 ? 0 : alpha > 255 ? 255 : alpha;
  const alphaHex = ('00' + alpha.toString(16)).slice(-2);
  color += alphaHex;
  return color;
}

/**
 * Determines the appropriate text color (white or black) based on the background color.
 *
 * This function analyzes the brightness of the given background color and provides guidance
 * on whether the text overlay should be white for optimal readability. The decision is
 * based on calculating the relative luminance of the background and using a threshold
 * value to decide if white text is more suitable than black.
 *
 * @param {string} backgroundColor - The background color in either HEX (#RRGGBB or #RGB)
 *                                    or RGB(A) (e.g., 'rgb(255, 255, 255)' or 'rgba(0, 0, 0, 1)') format.
 * @returns {boolean} - `true` if the text should be white based on the calculated luminance of the background;
 *                      `false` otherwise (text should be black).
 * @throws {Error} - If the provided background color is in an unsupported format.
 */
export const shouldTextBeWhite = (backgroundColor: string = ''): boolean => {
  if (!backgroundColor) return false;

  // Convert HEX or RGB(A) color to RGB components
  const getRGB = (color: string = '') => {
    if (color.startsWith('#')) {
      // HEX color
      const hex = color.replace('#', '');
      const bigint = parseInt(
        hex.length === 3
          ? hex
              .split('')
              .map((c) => c + c)
              .join('')
          : hex,
        16,
      );
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
      };
    } else if (color.startsWith('rgb')) {
      // RGB or RGBA color
      const rgbValues = color.match(/\d+/g)?.map(Number) ?? [];
      return { r: rgbValues[0], g: rgbValues[1], b: rgbValues[2] };
    }
    throw new Error('Unsupported color format');
  };

  const { r, g, b } = getRGB(backgroundColor);

  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return true for white text if luminance is below 0.5 (dark background)
  return luminance < 0.5;
};
