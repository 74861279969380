import dayjs from 'dayjs';
import { countBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Calendar as BigCalendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { FilterConditionOperatorEnum, useGetExperiencesLazyQuery } from '@/__generated__/graphql';
import Select from '@/components/basic/inputs/Select';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';
import { useAuth } from '@/hooks/useAuth';
import { ExperienceDetailsSideDrawer } from '@/pages/Experiences/ExperiencesPage/experienceDetails/ExperienceDetailsSideDrawer';
import { CityEnum, ExperienceStatusEnum, RolesEnum } from '@/shared/enums';
import { hasRoleAccess } from '@/shared/utils/rolesUtils';
import { toTitleCase } from '@/shared/utils/utils';

// https://stackoverflow.com/questions/76589100/react-big-calendar-and-dayjs-longer-events-not-showing-in-sundays-or-in-ending-d
dayjs.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);
const localizer = dayjsLocalizer(dayjs);
localizer.segmentOffset = 0;

export const Calendar = () => {
  const { user } = useAuth();
  const [selectedExperienceId, setSelectedExperienceId] = useState('');
  const [filters, setFilters] = useState<{ start: string; end: string; city: string }>(
    JSON.parse(localStorage.getItem('dashboardCalendarFilters') || 'null') || {
      start: dayjs().startOf('month').toString(),
      end: dayjs().endOf('month').toString(),
      city: '',
    },
  );
  const [getExperiences, { data, loading }] = useGetExperiencesLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const experiences = data?.getExperiences.experiences || [];
  const hasRole = hasRoleAccess(user!.roles, [
    RolesEnum.cityManager,
    RolesEnum.interviewer,
    RolesEnum.cityManager,
  ]);
  const categoryCount = countBy(experiences, 'experienceCategory');

  const handleRangeChange = (range: any) => {
    if (range.start) {
      setFilters({
        ...filters,
        start: range.start.toString(),
        end: range.end.toString(),
      });
    } else if (Array.isArray(range)) {
      if (range.length > 1) {
        setFilters({
          ...filters,
          start: range[0].toString(),
          end: dayjs(range[range.length - 1])
            .endOf('day')
            .toString(),
        });
      } else {
        setFilters({
          ...filters,
          start: dayjs(range[0]).startOf('day').toString(),
          end: dayjs(range[0]).endOf('day').toString(),
        });
      }
    }
  };

  const handleEventSelected = (event) => {
    setSelectedExperienceId(event.id);
  };

  useEffect(() => {
    if (!hasRole) {
      return;
    }

    if (filters) {
      localStorage.setItem('dashboardCalendarFilters', JSON.stringify(filters));
    }

    const filterArray = [
      {
        operator: FilterConditionOperatorEnum.GreaterThanEquals,
        field: 'date',
        value: filters.start,
      },
      {
        operator: FilterConditionOperatorEnum.LessThanEquals,
        field: 'date',
        value: filters.end,
      },
      {
        operator: FilterConditionOperatorEnum.Equals,
        field: 'status',
        value: ExperienceStatusEnum.published,
      },
    ];

    if (filters.city) {
      filterArray.push({
        operator: FilterConditionOperatorEnum.Equals,
        field: 'city',
        value: filters.city,
      });
    }

    getExperiences({
      variables: {
        filters: filterArray,
      },
    });
  }, [filters, hasRole]);

  if (!hasRole) {
    return null;
  }

  return (
    <SectionWithHeader
      title={'Experiences'}
      className={'w-full 2xl:max-w-[2000px]'}
      action={
        <>
          {loading && <div className={'loading bg-white'} />}
          <Select
            fullWidth={false}
            value={filters.city}
            onChange={(e) => setFilters({ ...filters, city: e.target.value })}
          >
            <option value={''}>All Cities</option>
            {Object.values(CityEnum).map((city) => (
              <option value={city} key={city}>
                {city}
              </option>
            ))}
          </Select>
        </>
      }
    >
      <div className={'flex'}>
        <div className={'h-[600px] min-w-0 flex-1'}>
          <BigCalendar
            defaultDate={dayjs(filters.start).add(15, 'days').toDate()}
            defaultView={'month'}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            onRangeChange={handleRangeChange}
            events={experiences.map((experiences) => ({
              id: experiences.id,
              title: experiences.title,
              start: dayjs(experiences.date).toDate(),
              end: dayjs(experiences.date).add(1, 'hour').toDate(),
            }))}
            onSelectEvent={handleEventSelected}
          />
        </div>

        <div className={'ml-4 gap-1 border-l p-4 text-left'}>
          {Object.entries(categoryCount).map(([category, count]) => (
            <div className={'flex min-w-[100px] justify-between gap-2'} key={category}>
              <h6 className={'text-lg font-bold'}>{toTitleCase(category)}</h6>
              <p className={'pl-4'}>{count}</p>
            </div>
          ))}
          {!Object.values(categoryCount)?.length && <p>No Experiences</p>}
        </div>
      </div>
      <ExperienceDetailsSideDrawer
        experienceId={selectedExperienceId}
        onClose={() => setSelectedExperienceId('')}
      />
    </SectionWithHeader>
  );
};
