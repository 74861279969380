import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

import { useGetPersonLazyQuery } from '@/__generated__/graphql';
import { RightSideDrawerBase } from '@/components/RightSideDrawerBase';
import { PersonPageContent } from '@/pages/PersonsPage/personDetailSections/PersonPageContent';

type Props = {
  personId?: string;
  onClose: (refetch?: boolean) => void;
};

export const PersonDetailsSideDrawer = ({ personId, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [getPerson, { error, loading, data }] = useGetPersonLazyQuery();
  const person = data?.getPerson;

  useEffect(() => {
    if (personId) {
      setIsOpen(true);
      const fetchPerson = async () => {
        await getPerson({ variables: { id: personId } });
      };

      fetchPerson();
    } else {
      setIsOpen(false);
    }
  }, [personId]);

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <RightSideDrawerBase
      isOpen={isOpen}
      onClose={() => onClose(true)}
      className={'p-0 pt-1 md:w-2/3 2xl:w-2/3'}
    >
      {error && <p className={'text-error'}>{error?.message}</p>}
      {person?.id ? (
        <PersonPageContent id={person?.id} />
      ) : loading ? (
        <div className={'loading loading-lg ml-10'} />
      ) : (
        'No Person'
      )}
    </RightSideDrawerBase>
  );
};
