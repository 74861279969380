import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import {
  Person,
  useGetPersonLazyQuery,
  useUpdateMembershipStatusMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { ModalBase } from '@/components/modals/ModalBase';
import { PersonMembershipStatusEnum } from '@/shared/enums';
import { getStripeUrl } from '@/shared/utils/utils';

type Props = {
  person: Person;
};

export const ReactivateAccountTrigger = ({ person }: Props) => {
  const [open, setOpen] = useState(false);
  const [getPerson, { loading: personLoading }] = useGetPersonLazyQuery();
  const [updateMembershipStatus, { loading: updateLoading }] = useUpdateMembershipStatusMutation();
  const loading = updateLoading || personLoading;

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateMembershipStatus({
        variables: {
          id: person.id,
          status: PersonMembershipStatusEnum.Active,
          payload: {},
        },
      });
      await getPerson({
        variables: { id: person.id },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      });

      enqueueSnackbar(`Account Reactivated successfully.`, {
        variant: 'success',
      });

      handleClose();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} color={'success'} size={'xs'}>
        {person.membershipStatus === PersonMembershipStatusEnum.Active &&
        person.subscription?.cancelAtPeriodEnd
          ? 'Cancel Scheduled Cancellation'
          : 'Reactivate Account'}
      </Button>
      <ModalBase
        isOpen={open}
        title={
          person.membershipStatus === PersonMembershipStatusEnum.Active &&
          person.subscription?.cancelAtPeriodEnd
            ? 'Cancel Scheduled Cancellation'
            : 'Reactivate Account'
        }
        onClose={handleClose}
      >
        <p className={'mb-4'}>
          {person.membershipStatus === PersonMembershipStatusEnum.Active &&
          person.subscription?.cancelAtPeriodEnd
            ? 'Ensure users subscription is still active.'
            : 'In order to reactivate, first attach a new subscription to there stripe account, then click "Reactivate".'}
        </p>

        <Button
          onClick={() => window.open(`${getStripeUrl()}/customers/${person?.stripeCustomerId}`)}
          color={'ghost'}
          size={'xs'}
          variant={'link'}
        >
          <span className={'text-base font-medium text-black'}>Open Stripe</span>
          <ArrowUpRightIcon color={'black'} className={'h-4 w-4'} />
        </Button>

        <div className={'mt-0 flex justify-end gap-2'}>
          <span className={'flex-1'} />
          <Button disabled={loading} loading={loading} onClick={handleSubmit} size={'xs'}>
            Reactivate
          </Button>
        </div>
      </ModalBase>
    </>
  );
};
